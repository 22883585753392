import React, { useState, useEffect } from "react"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import Footer from "../Footer"
import Aside from "../Aside"
import Header from "../Header"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import CustomCkEditor from "../CustomCkEditor"
import moment from "moment"
import axios from "axios"
import Loader from "../Loader"
import BackButton from "../BackButton"

const AddSystemMessage = (props) => {

    const { id } = useParams();
    const [query] = useSearchParams();
    const navigate = useNavigate();

    const [pageUpdate, setPageUpdate] = useState(true)
    const [systemMessageDate, setSystemMessageDate] = useState(null);
    const [textDetails, setTextDetails] = useState({
        system_message_title: '',
        system_message: null,
        url: '',
        status: 0,
    })
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        id && fetchAd(id);
    }, [])

    const fetchAd = async (id) => {
        setPageUpdate(false)
        const accessToken = sessionStorage.getItem('session')
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/system-message/${id}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        setTextDetails({
            system_message_title: response.data.data.system_message_title,
            system_message: response.data.data.system_message,
            url: response.data.data.url,
            status: response.data.data.status,
        })
        setSystemMessageDate(response.data.data.system_message_date)
        setPageUpdate(true)
    }

    const changeSystemMessageDate = (date) => {
        setSystemMessageDate(date)
    }

    const handTextDetails = (e) => {
        setTextDetails({
            ...textDetails, [e.target.name]: e.target.value,
        })
    }

    const getTextEditorValue = ({ value, name }) => {
        console.log(value)
        setTextDetails({
            ...textDetails,
            [name]: value,
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            setShowLoader(true)
            const payload = { 
                "system_message_title": textDetails.system_message_title, 
                "system_message": textDetails.system_message, 
                "status": textDetails.status, 
                "system_message_date": moment(systemMessageDate).format('YYYY-MM-DD'),
                "url": textDetails.url
            }
            const accessToken = sessionStorage.getItem('session')
            let endpoint = 'system-message';
            if (id) {
                endpoint = `update-system-message/${id}`;
            }
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/${endpoint}`, payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (query.get('returnTo')) {
                navigate(`/system-message?page=${query.get('returnTo')}`, {})
            } else {
                navigate(`/system-message?page=1`, {})
            }
        } catch (error) {

        } finally {
            setShowLoader(false)
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                {
                    pageUpdate ?
                        <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                            <Aside />
                            <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                                <Header />
                                <div>
                                    <BackButton />
                                </div>
                                <div className="px-9 py-9">
                                    <div className="mx-auto">
                                        <div className="mt-5 bg-white rounded-lg shadow">
                                            <div class="px-9 py-9 w-3/4 mx-auto">
                                                <div className="flex flex-col">
                                                    {/* <div className="mb-2 text-xs font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text cursor-pointer"><i className="fa fa-angle-left pr-2"></i>Back</div> */}
                                                    <div className="mb-2 text-2xl font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text">{id ? 'Edit Ad' : 'Create Ad'}</div>
                                                </div>
                                                <form onSubmit={submitForm}>
                                                    <div className="flex">
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="system_message_date" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        System Messsage Date
                                                                        {/* <Calendar /> */}
                                                                        {/* <input 
                                                        className="text-zinc-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"/> */}
                                                                    </label>
                                                                </div>
                                                                <div className="date-picker-style mt-2">
                                                                    <DatePicker
                                                                        selected={systemMessageDate}
                                                                        onChange={changeSystemMessageDate}
                                                                        minDate={new Date()}
                                                                        showIcon
                                                                        isClearable
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="system_message_title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        System Message Title
                                                                    </label>
                                                                </div>
                                                                <input
                                                                    name="system_message_title"
                                                                    onChange={handTextDetails}
                                                                    defaultValue={textDetails.system_message_title || ''}
                                                                    className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="system_message" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                System Message
                                                            </label>
                                                        </div>
                                                        <div className="ckEditor text-zinc-700 text-base">
                                                            <CustomCkEditor textData={textDetails.system_message} name={'system_message'} onChange={getTextEditorValue} />
                                                        </div>
                                                    </div>

                                                    <div className="flex">
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="Redirect URL" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Redirect URL
                                                                    </label>
                                                                </div>
                                                                <input name="url" defaultValue={textDetails.url || ''} onChange={handTextDetails} className="pr-8 text-zinc-700 placeholder-gray-600 px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                        <div>
                                                            <input className="cursor-pointer" type="radio" name="status" defaultValue={1} onChange={handTextDetails} defaultChecked={textDetails.status ? "checked" : ""} /> Active
                                                        </div>

                                                        <div className="mt-2">
                                                            <input className="cursor-pointer" type="radio" name="status" defaultValue={0} onChange={handTextDetails} defaultChecked={!textDetails.status ? "checked" : ""} /> Inactive
                                                        </div>
                                                    </div>

                                                    <button type="submit" class="mt-5 px-9 cursor-pointer rounded-lg bg-blue-700 p-2 text-white gradient-bg">Submit</button>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div> : ''
                }
            </React.Fragment>
        )
    }
}

export default AddSystemMessage