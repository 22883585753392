
import { useState } from 'react';
import axios from 'axios';
import '../assets/css/login-page.css';
import Loader from './Loader';

const LoginPage = () => {

    const [showLoader, setShowLoader] = useState(false)
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });

    const [error, setError] = useState('');

    const handleChange = (e) => {
        console.log('e', e.target.value);
        //setLoginData(e.target.value)
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        }
        )
    }

    const login = async (e) => {
        e.preventDefault();
        setShowLoader(true)
        try {
            const data = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/login`, loginData, {});
            console.log('data', data.data);
            sessionStorage.setItem('session',data.data.access_token)
            window.location.reload();
        } catch (error) {
            console.log('err', error.response.data.message);
            setShowLoader(false);
            setError(error.response.data.message)
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className="form-pos bg-ligher-gray">
            <div className="mx-auto xl:w-3/12 md:w-3/12 sm:w-4/12 min-[320px]:w-4/5 min-[320px]:mx-auto form-center">
                <div className="w-full p-4 sm:p-12.5 xl:p-17.5 bg-white rounded-lg shadow px-5 py-5">
                    <p className="mb-2 text-2xl font-bold text-black dark:text-white sm:text-title-xl2 gradient-text">Welcome Back</p>
                    <span className="mb-7 block text-default-gray">
                        Enter Your email password to sign in
                    </span>
                    <form onSubmit={login}>
                        <div className="mb-4">
                            <label className="mb-2.5 block font-medium text-xs text-regal-blue">Email</label>
                            <div className="mb-4">
                                <input 
                                    type="email" 
                                    placeholder="Email" 
                                    onChange={handleChange} 
                                    name='email'
                                    className="w-full text-xs rounded-lg border border-stroke p-2 text-black outline-none focus:border-primary focus-visible:shadow-none" required/>
                                <small className='text-red-600 text-xs'>{error}</small>
                            </div>
                            <div className="mb-9">
                                <label className="mb-2.5 block font-medium text-xs text-regal-blue">Password</label>
                                <input type="password" placeholder="Password" onChange={handleChange} name='password'
                                    className="w-full text-xs rounded-lg border border-stroke p-2 text-black outline-none focus:border-primary focus-visible:shadow-none" />
                            </div>
                            <div>
                                <input type="submit" placeholder="Sign In"
                                    className="w-full cursor-pointer rounded-lg bg-blue-700 p-2 text-white gradient-bg"
                                    value='Sign In' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )
    }
}

export default LoginPage;