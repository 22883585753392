import React from 'react'
import '../assets/css/loader.css'
import loader from '../assets/imgs/loader.gif'

export default function Loader() {
    return (
        <div className="loader">
            <img src={loader} alt="loader" />
        </div>
    )
}