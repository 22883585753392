import LoginPage from './components/LoginPage';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Users from './components/Users/Users';
import EditUser from './components/Users/EditUser'
import React, { useEffect, useState } from 'react';
import Dashboard from './components/Dashboard';
import ListAdvertisement from './components/Advertisement/ListAdvertisement';
import AddAdvertisement from './components/Advertisement/AddAdvertisement';
import ListStories from './components/StoryHeadline/ListStories';
import AddStories from './components/StoryHeadline/AddStories';
import ListSystemMessages from './components/SystemMessage/ListSystemMessages';
import AddSystemMessage from './components/SystemMessage/AddSystemMessage';
import Profile from './components/Users/Profile';
import Settings from './components/Settings';
import moment from 'moment';
import Post from './components/Users/Post';

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(()=>{
    sessionStorage.setItem('offset', moment().utcOffset())
    if(sessionStorage.getItem('session')){
      setIsAuthenticated(true)
    }
  },[])

  return (
    <Routes>
      {
        !isAuthenticated ? 
        <Route path='/' element={<LoginPage />} /> :
        <React.Fragment>
          <Route path='/' element={<Dashboard />} />
          <Route path='/users' element={<Users />} />
          <Route path='/user/edit/:id' element={<EditUser />} />
          <Route path='/user/profile/:id' element={<Profile />} />
          <Route path='/user/view-event/:id' element={<Post />} />
          <Route path='/advertisement' element={<ListAdvertisement />} />
          <Route path='/advertisement/add' element={<AddAdvertisement />} />
          <Route path='/advertisement/edit/:id' element={<AddAdvertisement />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/news' element={<ListStories />} />
          <Route path='/news/add' element={<AddStories />} />
          <Route path='/news/edit/:id' element={<AddStories />} />
          <Route path='/system-message' element={<ListSystemMessages />} />
          <Route path='/system-message/add' element={<AddSystemMessage />} />
          <Route path='/system-message/edit/:id' element={<AddSystemMessage />} />
        </React.Fragment>
      }
      <Route path='*' element={<h4>Page not found</h4>} />
    </Routes>
  );
}

export default App;
