import React, { useState, useEffect } from "react"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import Footer from "../Footer"
import Aside from "../Aside"
import Header from "../Header"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import CustomCkEditor from "../CustomCkEditor"
import moment from "moment"
import axios from "axios"
import Loader from "../Loader"
import BackButton from "../BackButton"

const AddStories = (props) => {

    const { id } = useParams();
    const [ query ] = useSearchParams();
    const navigate = useNavigate();

    const [pageUpdate, setPageUpdate] = useState(true);
    const [newsDate, setNewsDate] = useState(null);
    const [textDetails, setTextDetails] = useState({
        title: '',
        deal_notes: null,
        amount: null,
        url: '',
        status: 0,
    })
    const [imageData, setImageData] = useState({
        imageWidth: 0,
        imageHeight: 0,
        image: null,
        imagePreview: null,
        imagePreviewWidth: 0,
        imagePreviewHeight: 0,
    })
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        id && fetchStory(id);
    }, [])

    const fetchStory = async (id) => {
        setPageUpdate(false)
        const accessToken = sessionStorage.getItem('session')
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/news/${id}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        setTextDetails({
            news_title: response.data.data.news_title,
            news: response.data.data.news,
            redirection: response.data.data.redirection,
            url: response.data.data.url,
            news_details: response.data.data.news_details,
            status: response.data.data.status,
        })
        setImageData({
            ...imageData,
            imagePreview: response.data.data.image,
            imagePreviewWidth: 200,
            imagePreviewHeight: 200,
        })
        setNewsDate(response.data.data.news_date)
        setPageUpdate(true)
    }

    const changeNewsDate = (date) => {
        setNewsDate(date)
    }

    const handTextDetails = (e) => {
        setTextDetails({
            ...textDetails, [e.target.name]: e.target.value,
        })
    }

    const uploadImage = (e) => {
        const image = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (event) => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                console.log(img.naturalHeight, img.naturalWidth)
                setImageData({
                    ...imageData,
                    imageHeight: img.naturalHeight,
                    imageWidth: img.naturalWidth,
                    imagePreview: URL.createObjectURL(image),
                    imagePreviewWidth: 200,
                    imagePreviewHeight: 200,
                    imagePath: image,
                })
            }
        }
    }

    const getTextEditorValue = ({ value, name }) => {
        console.log(name, value)
        setTextDetails({
            ...textDetails,
            [name]: value,
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            setShowLoader(true)
            const formData = new FormData;
            formData.append('news_date', moment(newsDate).format("YYYY-MM-DD"))
            formData.append('news_title', textDetails.news_title)
            formData.append('news', textDetails.news)
            formData.append('redirection', textDetails.redirection) //details, url
            formData.append('news_details', textDetails.news_details || null)
            formData.append('url', textDetails?.url || null)
            formData.append('status', textDetails.status)
            formData.append('imageWidth', imageData.imageWidth)
            formData.append('imageHeight', imageData.imageHeight)
            formData.append('image', imageData.imagePath)

            /**
            news_title: asdhsd,
            news:sjkdsk,
            news_date: Sun Jul 21 2024 14:29:36 GMT+0530 (India Standard Time)
            image: (binary)
            redirection: details
            url: 
            news_details: <p>One piece world</p>
            status: inactive
            imageWidth: 735
            imageHeight: 549
             */

            const accessToken = sessionStorage.getItem('session')
            let endpoint = 'news';
            if (id) {
                endpoint = `update-news/${id}`;
                // formData.append('id', id);
            }
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/${endpoint}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (query.get('returnTo')) {
                navigate(`/news?page=${query.get('returnTo')}`, {})
            } else {
                navigate(`/news?page=1`, {})
            }
        } catch (error) {
            
        } finally {
            setShowLoader(false)
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                {
                    pageUpdate ?
                        <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                            <Aside />
                            <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                                <Header />
                                <div>
                                    <BackButton />
                                </div>
                                <div className="px-9 py-9">
                                    <div className="mx-auto">
                                        <div className="mt-5 bg-white rounded-lg shadow">
                                            <div class="px-9 py-9 w-3/4 mx-auto">
                                                <div className="flex flex-col">
                                                    {/* <div className="mb-2 text-xs font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text cursor-pointer"><i className="fa fa-angle-left pr-2"></i>Back</div> */}
                                                    <div className="mb-2 text-2xl font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text">{ id ? 'Edit Story' : 'Create Story' }</div>
                                                </div>
                                                <form onSubmit={submitForm}>
                                                    <div className="flex">
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="news_date" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        News Date
                                                                    </label>
                                                                </div>
                                                                <div className="date-picker-style mt-2">
                                                                    <DatePicker
                                                                        selected={newsDate}
                                                                        onChange={changeNewsDate}
                                                                        minDate={new Date()}
                                                                        showIcon
                                                                        isClearable
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="news_title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        News Title
                                                                    </label>
                                                                </div>
                                                                <input
                                                                    name="news_title"
                                                                    onChange={handTextDetails}
                                                                    defaultValue={textDetails.news_title || ''}
                                                                    className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
    
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                    {/* <div className="flex">
                                                        <div class="flex-grow pr-2">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Total Amount
                                                                    </label>
                                                                </div>
    
                                                                <input name="amount" defaultValue={textDetails.amount} onChange={handTextDetails} className="pr-8 text-zinc-700 placeholder-gray-600 px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="Redirect URL" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Redirect URL
                                                                    </label>
                                                                </div>
                                                                <input name="url" defaultValue={textDetails.url || ''} onChange={handTextDetails} className="pr-8 text-zinc-700 placeholder-gray-600 px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
                                                            </div>
                                                        </div>
                                                    </div> */}
    
                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="news_title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                News
                                                            </label>
                                                        </div>
                                                        <div className="ckEditor text-zinc-700 text-base">
                                                            <CustomCkEditor name={"news"} textData={textDetails.news} onChange={getTextEditorValue} />
                                                        </div>
                                                    </div>

                                                    <div className="pt-10">
                                                        <div>
                                                            <label for="news_title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                News Image
                                                            </label>
                                                        </div>
                                                        <input type="file" onChange={uploadImage} />
                                                        <img src={imageData.imagePreview } width={imageData.imagePreviewWidth} height={imageData.imagePreviewHeight} />
                                                    </div>

                                                    <div className="pt-5 text-zinc-700 py-2.5 mt-2 text-base flex">
                                                        <div>
                                                            Redirect To: 
                                                        </div>
                                                        <div className="ml-3">
                                                            <input className="cursor-pointer" type="radio" name="redirection" defaultValue={'url'} onChange={handTextDetails} defaultChecked={textDetails.redirection == 'url' ? "checked" : ""} /> URL
                                                        </div>
    
                                                        <div className="ml-2">
                                                            <input className="cursor-pointer" type="radio" name="redirection" defaultValue={'news_details'} onChange={handTextDetails} defaultChecked={!textDetails.redirection == 'details' ? "checked" : ""}/> Details
                                                        </div>
                                                    </div>

                                                    {
                                                        textDetails.redirection == 'url' ? 
                                                        <div className="pt-2">
                                                            <input name="url" defaultValue={textDetails.url || ''} onChange={handTextDetails} className="pr-8 text-zinc-700 placeholder-gray-600 px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
                                                        </div> : textDetails.redirection == 'news_details' ? 
                                                        <div className="pt-2">
                                                            <div className="ckEditor text-zinc-700 text-base">
                                                                <CustomCkEditor name={"news_details"} textData={textDetails.news_details} onChange={getTextEditorValue} />
                                                            </div>
                                                        </div> : ''
                                                    }
    
                                                    <div className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                        <div>
                                                            <input className="cursor-pointer" type="radio" name="status" defaultValue={1} onChange={handTextDetails} defaultChecked={textDetails.status ? "checked" : ""} /> Active
                                                        </div>
    
                                                        <div className="mt-2">
                                                            <input className="cursor-pointer" type="radio" name="status" defaultValue={0} onChange={handTextDetails} defaultChecked={!textDetails.status ? "checked" : ""}/> Inactive
                                                        </div>
                                                    </div>
    
                                                    <button type="submit" class="mt-5 px-9 cursor-pointer rounded-lg bg-blue-700 p-2 text-white gradient-bg">Submit</button>
                                                </form>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div> : ''
                }
            </React.Fragment>
        )
    }
}

export default AddStories