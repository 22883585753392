import bithdayImage from '../assets/imgs/birthday-min.png'
import calenderIcon from '../assets/imgs/calendar-icon.svg'
import travelIcon from '../assets/imgs/travel-icon.svg'
import workIcon from '../assets/imgs/breifcase-icon.svg'
import educationIcon from '../assets/imgs/education-icon.svg'
import relationshipIcon from '../assets/imgs/relationship-icon.svg'

export const defaultImage = (category) => {
    const map = {
        birthday: bithdayImage,
        custom: calenderIcon,
        travel: travelIcon,
        career: workIcon,
        education: educationIcon,
        relationships: relationshipIcon,
    };

    return map[category];
}