import React, { useEffect, useState, useRef } from "react";
import { NavLink, useSearchParams } from 'react-router-dom'
import Footer from "../Footer";
import Aside from "../Aside";
import Header from "../Header";
import axios from "axios";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";
import Loader from "../Loader";

const ListStories = () => {

    const [ query, setQuery ] = useSearchParams();

    const SORT_OPTIONS = {
        DATE_JOINED: 'created_at',
        BIRTH_DATE: 'date_of_birth',
        GENDER: 'gender',
        STATUS: 'active'
    }
    const DEFAULT_PAGE = 1;

    let timer = useRef()

    const [stories, setStories] = useState({});
    const [totalCount, setTotalCount] = useState(null);
    const [selectedStories, setSelectedStories] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [queryParams, setQueryParams] = useState({
        page: query.get('page') || DEFAULT_PAGE,
        limit: 10,
        sortBy: query.get('sortBy') || 'id',
        sortOrder: query.get('sortOrder') || 'DESC', //ASC, DESC,
        search: '',
    })

    const storiesData = async (pageNumber) => {
        try {
            setShowLoader(true)
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/get-news/${queryParams.limit}/id/${queryParams.sortOrder}?page=${pageNumber}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setStories(response.data.data.data);
            setTotalCount(response.data.data.total);
        } catch (err) {

        } finally {
            setShowLoader(false)
        }
    }

    const handlePageClick = (paginate) => {
        setQueryParams({ ...queryParams, page: paginate.selected + 1 })
        query.set('page', paginate.selected + 1)
        setQuery(query)
    };

    useEffect(() => {
        storiesData(queryParams.page)
    }, [queryParams.page, queryParams.sortOrder, queryParams.search])

    const searchStory = (e) => {
        e.preventDefault()
        const searchQuery = e.target.value
        clearTimeout(timer);
        if (searchQuery.trim() !== '') {
            try {
                timer = setTimeout(async () => {
                    const accessToken = sessionStorage.getItem('session')
                    const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/news/search/${searchQuery}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    })
                    setStories(response.data.data);
                }, 1000)
            } catch (error) {

            }
        } else {
            storiesData(queryParams.page)
        }
        query.set('page', DEFAULT_PAGE)
        setQuery(query)
    }

    const selectStory = (e) => {
        //If select all button is clicked
        if (e.target.checked) {
            if (e.target.value == 0) {
                setSelectedStories(stories.map(u => parseInt(u.id)))
            } else {
                setSelectedStories([...selectedStories, parseInt(e.target.value)])
            }
        } else {
            if (e.target.value == 0) {
                setSelectedStories([])
            } else {
                setSelectedStories(selectedStories.filter(u => u != parseInt(e.target.value)))
            }
        }
    }

    const setSort = (e) => {
        setQueryParams({
            ...queryParams,
            sortOrder: e.target.value
        })
        query.set('sortBy', queryParams.sortBy)
        query.set('sortOrder', e.target.value)
        setQuery(query)
    }

    const deleteStories = (e) => {
        e.preventDefault()
        if (selectedStories.length) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        setShowLoader(true)
                        const accessToken = sessionStorage.getItem('session')
                        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/news/delete`, selectedStories, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        })
                        setSelectedStories([])
                        const pageNo = selectedStories.length == queryParams.limit ? queryParams.page :  queryParams.page - 1;
                        setQueryParams({...queryParams, page: pageNo <= 1 ? 1 : pageNo})
                        query.set('page', pageNo)
                        setQuery(query)
                        await storiesData(pageNo)
                    } catch (error) {
                        
                    } finally {
                        setShowLoader(false)
                    }
                }
            });
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                    <Aside />
                    <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                        <Header />
    
                        {/* User Data */}
                        <div className="w-full p-4 md:p-6 2xl:p-10">
                            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                                <div className="col-span-12 xl:col-span-12">
                                    <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-2 pt-6 pb-2.5 shadow-xl sm:px-7.5 xl:pb-1">
                                      <div className="flex justify-between p-4 md:p-6 2xl:p-10">
                                        <div className="">
                                        <h5 className="mb-6 text-lg font-semibold text-indigo-900 px-5">Stories</h5>
                                        </div>
                                        <div className="add-ad">
                                            {/* <button className="p-2 bg-anchor-blue hover:bg-anchor-blue active:bg-anchor-blue focus:outline-none text-white rounded-md">Add New Advertisement</button> */}
                                            <NavLink to={"/news/add"} >
                                            <button className="p-2 focus:outline-none text-white rounded-md text-sm">Add New Story</button>
                                            </NavLink>
                                        </div>
                                      </div>
                                        {/* entries and search bar   */}
                                        <div className="flex justify-between p-4 md:p-6 2xl:p-10">
                                            <div className="w-full">
                                                <div className=" flex w-3/6 items-center">
                                                    <div className="font-medium text-zinc-400 xsm:text-base pr-5">
                                                        <p><i className="fa fa-trash icon-trash" onClick={deleteStories}></i></p>
                                                    </div>
                                                    <div className="relative inline-block user-entries cursor-pointer">
                                                        <span className="absolute top-1/2 right-3 -translate-y-1/2">
                                                            <i className="fa fa-angle-down text-lg text-zinc-400"></i>
                                                        </span>
                                                        <select onChange={setSort} className="cursor-pointer w-full relative inline-flex appearance-none bg-transparent py-1 pl-3 pr-8 outline-none text-sm font-bold text-zinc-400 xsm:text-base">
                                                            <option value="DESC" selected={queryParams.sortOrder == 'DESC' ? 'selected' : ''} className="cursor-pointer">Newest First</option>
                                                            <option value="ASC" selected={queryParams.sortOrder == 'ASC' ? 'selected' : ''} className="cursor-pointer">Oldest First</option>
                                                        </select>
                                                       
                                                    </div>
    
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    id='search-text'
                                                    name='query'
                                                    onChange={searchStory}
                                                    placeholder='Search...'
                                                    type='text'
                                                    ref={timer}
                                                    className="user-search user-search transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"
                                                />
                                            </div>
                                        </div>
    
    
                                        <div className="flex flex-col truncate ...">
                                            {/* heading of the table */}
                                            <div className="grid grid-cols-4 rounded-sm sm:grid-cols-4 border-b">
                                                <div className="p-2.5 xl:p-5 flex items-center justify-left">
                                                   <div className="flex items-center"><input
                                                        type="checkbox"
                                                        className="icon-checkbox cursor-pointer"
                                                        defaultValue={0}
                                                        // defaultChecked={ selectedUsers.length == users.length ? true : false } 
                                                        onChange={selectStory}
                                                        checked={(selectedStories.length && selectedStories.length == stories.length) ? true : false}
                                                    />
                                                    </div> 
                                                     <div className="flex items-center pl-5">
                                                    <h5 className="text-sm font-bold text-zinc-400 uppercase xsm:text-base">News Title</h5>
                                                </div>
                                                </div>
    
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-bold text-zinc-400 uppercase xsm:text-base">Date</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-bold text-zinc-400 uppercase xsm:text-base">Status</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-bold text-zinc-400 uppercase xsm:text-base">Action</h5>
                                                </div>
    
    
                                            </div>
                                            {/* content of the table*/}
                                            {/* first row */}
                                            {stories?.length > 0 && stories.map((story, i) => {
                                                return (
                                                    <div className="grid grid-cols-4 rounded-sm sm:grid-cols-4 relative" key={i}>
                                                        <div className="flex items-center justify-left p-2.5 xl:p-5 text-zinc-400">
                                                            <div className="flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="icon-checkbox cursor-pointer"
                                                                defaultValue={story.id}
                                                                onChange={selectStory}
                                                                checked={selectedStories.includes(story.id) ? true : false}
                                                            />
                                                            </div>
                                                            <div className="flex pl-4 items-center text-ellipsis overflow-hidden ...">
                                                                <p className='text-sm pl-2 text-regal-blue font-bold'>{story.news_title}</p>
                                                        </div>  
                                                        </div>
                                                        <div className="flex items-center justify-center p-1 xl:p-2 text-zinc-400 font-bold">
                                                            <p className='text-sm text-ellipsis overflow-hidden ...'>{moment(story.news_date).format('MMMM DD, YYYY')}</p>
                                                        </div>
                                                        <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400 font-bold">
                                                            <p className='text-sm'>{story.status ? 'Active' : 'Inactive'}</p>
                                                        </div>
                                                        <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400 font-medium">
                                                            <div className='bg-zinc-200 w-7 h-7 rounded-md shadow-xl relative cursor-pointer'>
                                                                <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4">
                                                                <NavLink to={`/news/edit/${story.id}?returnTo=${queryParams.page}`} >
                                                                    <i className="text-md font-medium text-icon-color fa fa-edit" ></i>
                                                                </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                )
                                            })}
    
                                            {/* second row */}
    
                                        </div>
    
    
                                        {/* Pagination */}
                                        <div className="flex justify-end p-4 md:p-6 2xl:p-10">
                                            <div className="pagination-nav">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={<span><i className="fa fa-angle-right"></i></span>}
                                                    onPageChange={handlePageClick}
                                                    pageCount={Math.ceil(totalCount / queryParams.limit)}
                                                    previousLabel={<span><i className="fa fa-angle-left"></i></span>}
                                                    forcePage={queryParams.page-1}
                                                />
                                            </div>
                                        </div>
    
    
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListStories