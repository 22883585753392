import React, { useEffect, useState } from 'react'
import Aside from "./Aside"
import Header from "./Header"
import Footer from "./Footer"
import axios from 'axios';
import moment from "moment";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Loader from './Loader';
import LoginAndPostsChart from './Charts/LoginAndPostsChart';
import { NavLink } from 'react-router-dom';
import { defaultImage } from '../helpers/DefaultPostImage';
import { titlecase } from '../helpers/String';
import defaultProfileImage from '../assets/imgs/noimage.png'
import '../assets/css/custom.css'
import GrowthChart from './Charts/GrowthChart';

const Dashboard = () => {

    const RANGES = {
        TODAY: 'today',
        LAST_WEEK: 'week',
        LAST_MONTH: 'month',
        LAST_YEAR: 'year',
        CUSTOM: 'custom'
    }

    const GROWTH_TYPE = {
        USERS: 'users',
        LIFE_EVENTS: 'events',
        SUB_ACCOUNTS: 'subaccounts',
    }

    let endDate = new Date();

    const [showLoader, setShowLoader] = useState(false);
    const [dashBoard, setDashBoard] = useState({});
    const [customDateRange, setCustomDateRange] = useState({
        isEnabled: false,
        startDate: '',
        endDate: '',
        type: RANGES.TODAY,
        growthType: GROWTH_TYPE.USERS
    })
    const [showGrowthGraph, setShowGrowthGraph] = useState(true);

    useEffect(() => {
        dashboardData()
    }, [])

    const dashboardData = async () => {
        try {
            setShowLoader(true)
            const today = moment().format('YYYY-MM-DD')
            const accessToken = sessionStorage.getItem('session')
            const [dashboardData, rangeData, growthGraphData] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/dashboard`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }),
                axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/range-data?type=${customDateRange.type}&startDate=${today}&endDate=${today}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }),
                axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/growth-data?growthType=${customDateRange.growthType}&type=${customDateRange.type}&startDate=${today}&endDate=${today}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
            ])
            await new Promise(resolve => {
                setDashBoard({
                    totalUsers: dashboardData.data.totalUsers,
                    totalPosts: dashboardData.data.totalPosts,
                    subAccounts: dashboardData.data.subAccounts,
                    posts: dashboardData.data.newPosts,
                    users: dashboardData.data.newUsers,
                    invitesSent: dashboardData.data?.invitesSentCount || 0,
                    statistics: {
                        usersCount: rangeData.data.usersCount,
                        postsCount: rangeData.data.postsCount,
                        subAccountsCount: rangeData.data.subAccountsCount,
                        usersGrowthPercentage: getPercentage(rangeData.data.usersCount,rangeData.data.usersCountPast),
                        postsGrowthPercentage: getPercentage(rangeData.data.postsCount,rangeData.data.postsCountPast),
                        subAccountsGrowthPercentage: getPercentage(rangeData.data.subAccountsCount,rangeData.data.subAccountsCountPast),
                        startDate: rangeData.data.startDate,
                        endDate: rangeData.data.endDate,
                    },
                    growthData: growthGraphData.data.dataset
                })
                resolve({})
            })
            setShowLoader(false);
        } catch (err) {
            setShowLoader(false);
        }
    }

    const getRangeValue = async (e) => {
        if (e.target.value == RANGES.CUSTOM) {
            setCustomDateRange({
                ...customDateRange,
                isEnabled: true,
                startDate: new Date(),
                endDate: new Date(),
                // type: RANGES.CUSTOM,
            })
        } else {
            let startDate = ''
            let endDate = ''
            if (e.target.value == RANGES.TODAY) {
                startDate = moment().format('YYYY-MM-DD')
                endDate = startDate
                setCustomDateRange({
                    ...customDateRange,
                    isEnabled: false,
                    startDate: startDate,
                    endDate: endDate,
                    type: RANGES.TODAY
                })
            } else if (e.target.value == RANGES.LAST_WEEK) {
                startDate = moment().subtract(1, 'w').format('YYYY-MM-DD')
                endDate = moment().subtract(1, 'd').format('YYYY-MM-DD')
                setCustomDateRange({
                    ...customDateRange,
                    isEnabled: false,
                    startDate: startDate,
                    endDate: endDate,
                    type: RANGES.LAST_WEEK
                })
            } else if (e.target.value == RANGES.LAST_MONTH) {
                startDate = moment().subtract(1, 'month').format('YYYY-MM-DD')
                endDate = moment().format('YYYY-MM-DD')
                setCustomDateRange({
                    ...customDateRange,
                    isEnabled: false,
                    startDate: startDate,
                    endDate: endDate,
                    type: RANGES.LAST_MONTH
                })
            } else if (e.target.value == RANGES.LAST_YEAR) {
                startDate = moment().subtract(1, 'y').format('YYYY-MM-DD')
                endDate = moment().format('YYYY-MM-DD')
                setCustomDateRange({
                    ...customDateRange,
                    isEnabled: false,
                    startDate: startDate,
                    endDate: endDate,
                    type: RANGES.LAST_YEAR
                })
            }

            await fetchRangedData({
                type: e.target.value,
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
            })
        }
    }

    const selectDateRange = async (range) => {
        endDate = range.selection.endDate;
        setCustomDateRange({
            ...customDateRange,
            startDate: new Date(range.selection.startDate),
            endDate: new Date(range.selection.endDate),
        })
    }

    const fetchRangedData = async (range) => {
        setShowGrowthGraph(false);
        const accessToken = sessionStorage.getItem('session')
        const [rangeData, growthData] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/range-data?type=${range.type}&startDate=${range.startDate}&endDate=${range.endDate}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }),
            axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/growth-data?growthType=${customDateRange.growthType}&type=${range.type}&startDate=${range.startDate}&endDate=${range.endDate}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }),
        ])
        setDashBoard({
            ...dashBoard,
            statistics: {
                usersCount: rangeData.data.usersCount,
                postsCount: rangeData.data.postsCount,
                subAccountsCount: rangeData.data.subAccountsCount,
                usersGrowthPercentage: getPercentage(rangeData.data.usersCount,rangeData.data.usersCountPast),
                postsGrowthPercentage: getPercentage(rangeData.data.postsCount,rangeData.data.postsCountPast),
                subAccountsGrowthPercentage: getPercentage(rangeData.data.subAccountsCount,rangeData.data.subAccountsCountPast),
                startDate: rangeData.data.startDate,
                endDate: rangeData.data.endDate,
            },
            growthData: growthData.data.dataset,
        })
        setShowGrowthGraph(true);
    }

    const handleRangeSelection = async ([startSelected, endSelected]) => {
        if (startSelected == 0 && endSelected == 0) {
            await fetchRangedData({
                type: RANGES.CUSTOM,
                startDate: moment(customDateRange.startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
            })
            setCustomDateRange({
                ...customDateRange,
                isEnabled: false,
                startDate: moment(customDateRange.startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                type: RANGES.CUSTOM,
            })
        }
    }

    const changeGrowthType = async (growthType) => {
        setShowGrowthGraph(false)
        try {
            setCustomDateRange({
                ...customDateRange,
                growthType: growthType
            })
    
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/admin/growth-data?
                growthType=${growthType}&type=${customDateRange.type}&startDate=${customDateRange.startDate}&endDate=${customDateRange.endDate}`, 
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            )
    
            setDashBoard({
                ...dashBoard,
                growthData: response.data.dataset
            })
            setShowGrowthGraph(true)
        } catch (error) {
            setShowGrowthGraph(true);
        }
    }

    const getDenominator = (value) => {
        return value > 0 ? value : 1;
    }

    const getPercentage = (value, total) => {
        return (( value / getDenominator(total) ) * 100).toFixed(2);
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                    <Aside />
                    <div className='w-3/4 flex flex-1 flex-col overflow-x-hidden bg-ligher-gray'>
                        <Header />
                        {/* Boxes */}
                        <div className="max-w-screen-2xl p-4 md:p-6 2xl:p-10 pb-2">

                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
                                <div className="rounded-lg border border-stroke bg-white shadow-xl dark:border-strokedark dark:bg-boxdark">
                                    <div className="flex justify-between p-4 items-center">
                                        <div>
                                            <h4 className="text-title-md font-bold text-default-gray text-sm">Total Users</h4>
                                            <h6 className="text-title-md font-bold text-regal-blue">{dashBoard?.totalUsers || 0}</h6>
                                        </div>
                                        <div className="gradient-bg w-10 h-10 rounded-lg shadow-xl relative">
                                            <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-white">
                                                <span><i className="fa fa-users"></i></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="rounded-lg border border-stroke bg-white shadow-xl dark:border-strokedark dark:bg-boxdark">
                                    <div className="flex justify-between p-4 items-center">
                                        <div>
                                            <h4 className="text-title-md font-bold text-default-gray text-sm">Life Events</h4>
                                            <h6 className="text-title-md font-bold text-regal-blue">{dashBoard?.totalPosts || 0}</h6>
                                        </div>
                                        <div className="gradient-bg w-10 h-10 rounded-lg shadow-xl relative">
                                            <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-white">
                                                <span><i className="fa fa-user"></i></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="rounded-lg border border-stroke bg-white shadow-xl dark:border-strokedark dark:bg-boxdark">
                                    <div className="flex justify-between p-4 items-center">
                                        <div>
                                            <h4 className="text-title-md font-bold text-default-gray text-sm">Sub Accounts</h4>
                                            <h6 className="text-title-md font-bold text-regal-blue">{dashBoard?.subAccounts || 0}</h6>
                                        </div>
                                        <div className="gradient-bg w-10 h-10 rounded-lg shadow-xl relative">
                                            <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-white">
                                                <span><i className="fa fa-window-restore"></i></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="rounded-lg border border-stroke bg-white shadow-xl dark:border-strokedark dark:bg-boxdark">
                                    <div className="flex justify-between p-4 items-center">
                                        <div>
                                            <h4 className="text-title-md font-bold text-default-gray text-sm">Invites Sent</h4>
                                            <h6 className="text-title-md font-bold text-regal-blue">{dashBoard?.invitesSent || 0}</h6>
                                        </div>
                                        <div className="gradient-bg w-10 h-10 rounded-lg shadow-xl relative">
                                            <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-white">
                                                <span><i class="fa fa-share-alt" aria-hidden="true"></i></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Graph */}
                        <div className="max-w-screen-2xl p-4 md:p-6 2xl:p-10 pb-2">
                            <div className="flex">
                                <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                                <div className="col-span-12 xl:col-span-6 graph-chart">
                                    <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-2 pt-6 pb-2.5 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-1 w-full">
                                        <div className="flex justify-between p-4 items-center">

                                            <div className='none'>
                                                <div className='font-bold text-default-gray text-xl flex'>
                                                    <div>
                                                        Growth
                                                    </div>
                                                    {/* {
                                                        dashBoard?.statistics?.startDate ? 
                                                        <div className='ml-2'>
                                                            ({`${moment(dashBoard?.statistics?.startDate).format('MM/DD/YYYY')}-${moment(dashBoard?.statistics?.endDate).format('MM/DD/YYYY')}`})
                                                        </div> : ''
                                                    } */}
                                                </div>
                                                <div className='font-normal text-default-gray text-sm'><span className='font-medium'>({
                                                    customDateRange.growthType == GROWTH_TYPE.USERS ?
                                                    dashBoard?.statistics?.usersGrowthPercentage || 0 : 
                                                    customDateRange.growthType == GROWTH_TYPE.LIFE_EVENTS ? 
                                                    dashBoard?.statistics?.postsGrowthPercentage || 0 : 
                                                    dashBoard?.statistics?.subAccountsGrowthPercentage || 0
                                                }%)</span>{
                                                    customDateRange.type == RANGES.TODAY ? ' yesterday' :
                                                    customDateRange.type == RANGES.LAST_WEEK ? ' last week': 
                                                    customDateRange.type == RANGES.LAST_MONTH ? ' last month' :
                                                    customDateRange.type == RANGES.LAST_YEAR ? ' last year': 
                                                    customDateRange.type == RANGES.CUSTOM ? ' total Growth' : ''
                                                }</div>
                                            </div>
                                            <div className='relative'>
                                                {/* <span className="absolute top-1/2 right-3 -translate-y-1/2">
                                            <i className="fa fa-angle-down text-lg text-white"></i>
                                            </span> */}
                                                <div className='growth-dropdown inline-block '>
                                                    <select name='statistics' onChange={getRangeValue} className='growthSelect'>
                                                        <option style={{ borderBottom: '1px solid white' }} value={RANGES.TODAY} selected={customDateRange.type == RANGES.TODAY ? 'selected' : ''}>Today</option>
                                                        <option value={RANGES.LAST_WEEK} selected={customDateRange.type == RANGES.LAST_WEEK ? 'selected' : ''}>Last Week</option>
                                                        <option value={RANGES.LAST_MONTH} selected={customDateRange.type == RANGES.LAST_MONTH ? 'selected' : ''}>Last Month</option>
                                                        <option value={RANGES.LAST_YEAR} selected={customDateRange.type == RANGES.LAST_YEAR ? 'selected' : ''}>Last Year</option>
                                                        <option value={RANGES.CUSTOM} selected={customDateRange.type == RANGES.CUSTOM ? 'selected' : ''}>Custom Range</option>
                                                    </select>
                                                    {
                                                        customDateRange.isEnabled ?
                                                            <React.Fragment>
                                                                <DateRange
                                                                    ranges={[{ key: 'selection', startDate: customDateRange.startDate, endDate: customDateRange.endDate }]}
                                                                    onChange={selectDateRange}
                                                                    maxDate={new Date()}
                                                                    showSelectionPreview={true}
                                                                    moveRangeOnFirstSelection={false}
                                                                    onRangeFocusChange={handleRangeSelection}
                                                                />
                                                                {/* <button type='button' onClick={fetchCustomRangedData} className='cal-btn'>OK</button> */}
                                                            </React.Fragment> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                showGrowthGraph ? 
                                                <GrowthChart customDateRange={customDateRange} growthData={dashBoard.growthData} /> : ''
                                            }
                                        </div>

                                        <div>

                                            <div className="flex justify-between p-4 items-center">

                                                <div className={`${customDateRange.growthType == GROWTH_TYPE.USERS ? 'enableWidget' : 'disableWidget'}`}>
                                                    <button className="flex items-baseline" onClick={changeGrowthType.bind(this, GROWTH_TYPE.USERS)}>
                                                        <span className='shadow-xl'><i class="bg-gradient-purple px-2 py-1 rounded-sm text-white fa fa-user text-xs"></i></span>
                                                        <div className='flex flex-col text-start'>
                                                            <div className='font-semibold text-default-gray text-xs pl-1'>Users</div>
                                                        </div>
                                                    </button>
                                                    <div className='flex flex-col text-start'>
                                                        <div className="font-bold text-regal-blue text-xl pt-2 pl-1">{dashBoard?.statistics?.usersCount || 0}</div>
                                                    </div>
                                                </div>

                                                <div className={`${customDateRange.growthType == GROWTH_TYPE.LIFE_EVENTS ? 'enableWidget' : 'disableWidget'}`}>
                                                    <button className="flex items-baseline" onClick={changeGrowthType.bind(this, GROWTH_TYPE.LIFE_EVENTS)}>
                                                        <span className='shadow-xl'><i class="gradient-bg px-2 py-1 rounded-sm text-white fa fa-user text-xs"></i></span>
                                                        <div className='flex flex-col text-start'>
                                                            <div className='font-semibold text-default-gray text-xs pl-1'>Life Events</div>
                                                        </div>
                                                    </button>
                                                    <div className='flex flex-col text-start'>
                                                        <div className="font-bold text-regal-blue text-xl pt-2 pl-1">{dashBoard?.statistics?.postsCount || 0}</div>
                                                    </div>
                                                </div>

                                                <div className={`${customDateRange.growthType == GROWTH_TYPE.SUB_ACCOUNTS ? 'enableWidget' : 'disableWidget'}`}>
                                                    <button className="flex items-baseline" onClick={changeGrowthType.bind(this, GROWTH_TYPE.SUB_ACCOUNTS)}>
                                                        <span className='shadow-xl'><i class="bg-gradient-orange px-1 py-1 rounded-sm text-white fa fa-window-restore text-xs"></i></span>
                                                        <div className='flex flex-col text-start'>
                                                            <div className='font-semibold text-default-gray text-xs pl-1'>Sub Accounts</div>
                                                        </div>
                                                    </button>
                                                    <div className='flex flex-col text-start'>
                                                        <div className="font-bold text-regal-blue text-xl pt-2 pl-1">{dashBoard?.statistics?.subAccountsCount || 0}</div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* users : {dashBoard?.statistics?.usersCount || 0}
                                            life events : {dashBoard?.statistics?.postsCount || 0}
                                            sub accounts : {dashBoard?.statistics?.subAccountsCount || 0}
                                            invites sent : {dashBoard?.statistics?.invitesSentCount || 0} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-6 graph-chart">
                                    <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-1 login-posts-graph">
                                        <div className="login-posts-graph-child">
                                            <LoginAndPostsChart />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="flex mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                                <div className="col-span-12 xl:col-span-7">
                                    <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-xl dark:border-strokedark  sm:px-7.5 xl:pb-1">
                                        <h5 className="mb-6 text-lg font-semibold text-indigo-900">Latest Post</h5>
                                        <div className="flex flex-col">
                                            {/* heading of the table */}
                                            <div className="grid grid-cols-4 rounded-sm dark:bg-meta-4 sm:grid-cols-4 border-b">
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-semibold uppercase xsm:text-base text-zinc-400">Title</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-semibold uppercase xsm:text-base text-zinc-400">Author</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-semibold uppercase xsm:text-base text-zinc-400">Date</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5">
                                                    <h5 className="text-sm font-semibold uppercase xsm:text-base text-zinc-400">Category</h5>
                                                </div>
                                            </div>

                                            {/* content of the table*/}

                                            {dashBoard?.posts?.length > 0 && dashBoard?.posts.map((post, i) => {
                                                return (
                                                    <>
                                                        <div className="grid grid-cols-4 sm:grid-cols-4 border-b border-stroke dark:border-strokedark" key={`post-no-${i}`}>
                                                            <div className="flex items-center justify-left p-2.5 xl:p-5">
                                                                <div className='flex items-center'>
                                                                    <img src={defaultImage(post?.event_category)} className='h-7 w-7' />
                                                                    <NavLink to={`/user/view-event/${post.id}`}><p className='text-xs pl-2 font-semibold text-anchor-blue underline cursor-pointer'>{post?.event_title}</p></NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-left p-2.5 xl:p-5">
                                                                <div className='flex items-center'>
                                                                    <img src={post?.user.images.profile_picture_thumb || defaultProfileImage} className='h-7 w-7 rounded-full' />
                                                                    <NavLink to={`/user/profile/${post?.user.id}`}><p className='text-xs pl-2 text-regal-blue font-semibold'>{post?.user.fname + ' ' + post?.user.lname}</p></NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5">
                                                                <p className='text-xs text-regal-blue font-semibold'>{moment(post?.created_at).format('MM/DD/YYYY')}</p>
                                                            </div>
                                                            <div className="flex items-center p-2.5 xl:p-5">
                                                                <p className='text-xs text-regal-blue font-semibold'>{titlecase(post?.event_category)}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                {/* Newest users */}
                                <div className="col-span-12 xl:col-span-5">
                                    <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-2 pt-6 pb-2.5 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-1">
                                        <h5 className="mb-6 text-lg font-semibold text-indigo-900 px-5">Newest Users</h5>
                                        <div className="flex flex-col">
                                            {/* heading of the table */}
                                            <div className="grid grid-cols-3 rounded-sm dark:bg-meta-3 sm:grid-cols-3 border-b">
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-semibold text-zinc-400 uppercase xsm:text-base">Name</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-semibold text-zinc-400 uppercase xsm:text-base">Date Joined</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-semibold text-zinc-400 uppercase xsm:text-base">Status</h5>
                                                </div>
                                            </div>
                                            {/* content of the table*/}

                                            {/* first row */}

                                            {dashBoard?.users?.length > 0 && dashBoard?.users.map((user, i) => {
                                                return (
                                                    <>
                                                        <div className="grid grid-cols-3 sm:grid-cols-3 border-b border-stroke dark:border-strokedark" key={`user-no-${i}`}>
                                                            <div className="flex items-center justify-left p-2.5 xl:p-5">
                                                                <div className='flex items-center'>
                                                                    <img src={user.images.profile_picture_thumb || defaultProfileImage} className='h-7 w-7 rounded-full' />
                                                                    <NavLink to={`/user/profile/${user.id}`}><p className='text-xs pl-2 text-regal-blue font-semibold'>{user?.fname + ' ' + user.lname}</p></NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400">
                                                                <p className='text-xs font-semibold'>{moment(user?.created_at).format('MM/DD/YYYY')}</p>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400">
                                                                <p className='text-xs font-semibold'>Verified</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Dashboard