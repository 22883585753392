import React, { useEffect, useState, useRef } from "react";
import Footer from "../Footer";
import Aside from "../Aside";
import Header from "../Header";
import axios from "axios";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import Loader from "../Loader";
import defaultProfileImage from '../../assets/imgs/noimage.png'

const Users = () => {

    const [query, setQuery] = useSearchParams();

    const SORT_OPTIONS = {
        DATE_JOINED: 'created_at',
        BIRTH_DATE: 'date_of_birth',
        GENDER: 'gender',
        STATUS: 'active'
    }
    const DEFAULT_PAGE = 1;

    let timer = useRef()

    const [users, setUsers] = useState({});
    const [totalCount, setTotalCount] = useState(null);
    const [userOption, setUserOption] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([])

    const [queryParams, setQueryParams] = useState({
        page: query.get('page') || DEFAULT_PAGE,
        limit: query.get('limit') || 10,
        sortBy: query.get('sortBy') || 'created_at', //"date_of_birth", "gender", "active"],
        sortOrder: query.get('sortOrder') || 'DESC', //ASC, DESC,
        search: '',
    })

    const [showLoader, setShowLoader] = useState(false)

    const userData = async (pageNumber) => {
        try {
            setShowLoader(true);
            const accessToken = sessionStorage.getItem('session')
            let endpoint = 'api/admin/users';
            if (queryParams.search) {
                endpoint = `/api/admin/user/search/${queryParams.search}`
            }
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/${endpoint}?page=${pageNumber}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&limit=${queryParams.limit}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            console.log('res', response.data.data.data);
            console.log('res pages', response.data.data);
            const data = response.data.data.data;
            setUsers(data);
            setTotalCount(response.data.data.total);
        } catch (err) {

        } finally {
            setShowLoader(false)
        }
    }

    const handlePageClick = (paginate) => {
        setQueryParams({ ...queryParams, page: paginate.selected + 1 })
        query.set('page', paginate.selected + 1)
        setQuery(query)

    };

    useEffect(() => {
        userData(queryParams.page)
    }, [queryParams.page, queryParams.limit, queryParams.sortBy, queryParams.sortOrder, queryParams.search])

    const setLimit = (e) => {
        setQueryParams({ ...queryParams, limit: e.target.value })
        query.set('page', DEFAULT_PAGE)
        query.set('limit', e.target.value)
        setQuery(query)
    }

    const sortTable = (sortColumn) => {
        console.log(sortColumn);
        // const sortDirection = queryParams.sortBy == sortColumn ? (queryParams.sortOrder == 'ASC' ? 'DESC': 'ASC') : queryParams.sortOrder;
        let sortDirection = 'ASC';
        if (queryParams.sortBy == sortColumn) {
            if (queryParams.sortOrder == 'ASC') {
                sortDirection = 'DESC'
            } else {
                sortDirection = 'ASC'
            }
        } else {
            sortDirection = queryParams.sortOrder;
        }
        setQueryParams({ ...queryParams, sortBy: sortColumn, sortOrder: sortDirection })
        query.set('page', DEFAULT_PAGE)
        query.set('sortBy', sortColumn)
        query.set('sortOrder', sortDirection)
        setQuery(query)
    }

    const showUserOption = (e) => {
        const userId = e.target.id.split("-").at(-1)
        //setUserOption(userId)
        if (userId == userOption) {
            setUserOption(null)
        } else {
            setUserOption(userId)
        }
    }

    const enableDisableUser = async (userId, status) => {
        try {
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.post(
                `${process.env.REACT_APP_BASEURL}/api/admin/user/change-status`,
                {
                    user_id: userId,
                    status: status == 1 ? 0 : 1
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
            await userData(queryParams.page)
        } catch (error) {

        }
    }

    const searchUser = (e) => {
        e.preventDefault()
        const searchQuery = e.target.value
        clearTimeout(timer);
        if (searchQuery.trim() !== '') {
            try {
                timer = setTimeout(async () => {
                    setQueryParams({ ...queryParams, page: query == queryParams.search ? queryParams.page : 1, search: searchQuery, });
                    query.set('page', DEFAULT_PAGE)
                    setQuery(query)
                }, 1000)
            } catch (error) {

            }
        } else {
            setQueryParams({ ...queryParams, page: 1, search: '', });
            query.set('page', DEFAULT_PAGE)
            setQuery(query)
        }
    }

    const selectUser = (e) => {
        //If select all button is clicked
        if (e.target.checked) {
            if (e.target.value == 0) {
                setSelectedUsers(users.map(u => parseInt(u.id)))
            } else {
                setSelectedUsers([...selectedUsers, parseInt(e.target.value)])
            }
        } else {
            if (e.target.value == 0) {
                setSelectedUsers([])
            } else {
                setSelectedUsers(selectedUsers.filter(u => u != parseInt(e.target.value)))
            }
        }
    }

    const deleteUsers = (e) => {
        e.preventDefault()
        if (selectedUsers.length) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setShowLoader(true);
                    const accessToken = sessionStorage.getItem('session')
                    const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/users/delete`, selectedUsers, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    })
                    const pageNo = selectedUsers.length == queryParams.limit ? queryParams.page :  queryParams.page - 1;
                    setQueryParams({...queryParams, page: pageNo})
                    query.set('page', pageNo)
                    setQuery(query)
                }
            });
        }
    }

    const getRoleName = (role) => {
        if (['parent', 'child'].includes(role)) {
            return 'FAMILY';
        }
        return role.toUpperCase();
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                    <Aside />
                    <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                        <Header />

                        {/* User Data */}
                        <div className="w-full mx-auto p-4 md:p-6 2xl:p-10">
                            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                                <div className="col-span-12 xl:col-span-12">
                                    <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-2 pt-6 pb-2.5 shadow-xl sm:px-7.5 xl:pb-1">
                                        <h5 className="mb-6 text-lg font-semibold text-indigo-900 px-5">Users</h5>

                                        {/* entries and search bar   */}
                                        <div className="flex justify-between p-4 md:p-6 2xl:p-10">
                                            <div className="w-full">
                                                <div className=" flex w-3/6 items-center">
                                                    <div className="relative inline-block  w-full cursor-pointer">
                                                        {/* <span className="absolute top-1/2 right-3 -translate-y-1/2">
                                                            <i className="fa fa-angle-down text-lg text-zinc-400"></i>
                                                        </span> */}
                                                        <div className="option-dropdown">
                                                        <select onChange={setLimit} className="optionSelect w-full">
                                                            <option value={10} selected={queryParams.limit == 10 ? "selected" : ""} className="cursor-pointer">10</option>
                                                            <option value={25} selected={queryParams.limit == 25 ? "selected" : ""} className="cursor-pointer">25</option>
                                                            <option value={50} selected={queryParams.limit == 50 ? "selected" : ""} className="cursor-pointer">50</option>
                                                            <option value={100} selected={queryParams.limit == 100 ? "selected" : ""} className="cursor-pointer">100</option>
                                                            <option value={250} selected={queryParams.limit == 250 ? "selected" : ""} className="cursor-pointer">250</option>
                                                        </select>
                                                        </div>
                                                    </div>
                                                    <div className="w-full pl-2 text-sm font-medium text-zinc-400 xsm:text-base">
                                                        <p>Entries Per Page</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    id='search-text'
                                                    name='query'
                                                    onChange={searchUser}
                                                    placeholder='Search...'
                                                    type='text'
                                                    ref={timer}
                                                    defaultValue={queryParams.search}
                                                    className="user-search transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"
                                                />
                                            </div>
                                        </div>


                                        <div className="flex flex-col">
                                            {/* heading of the table */}
                                            <div className="grid grid-cols-8 rounded-sm sm:grid-cols-8 border-b">
                                                <div className="p-2.5 xl:p-5 flex items-center justify-between">
                                                    <input
                                                        type="checkbox"
                                                        className="icon-checkbox cursor-pointer"
                                                        defaultValue={0}
                                                        // defaultChecked={ selectedUsers.length == users.length ? true : false } 
                                                        onChange={selectUser}
                                                        checked={selectedUsers.length == users.length ? true : false}
                                                    />
                                                    {
                                                        selectedUsers.length ?
                                                            <i className="fa fa-trash icon-trash cursor-pointer" onClick={deleteUsers}></i> : ''
                                                    }
                                                    <div className="flex items-center">
                                                        <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Name</h5>
                                                    </div>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Username</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Email</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Role</h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Date Joined <i className="fa fa-sort cursor-pointer" onClick={sortTable.bind(this, SORT_OPTIONS.DATE_JOINED)} aria-hidden="true"></i>                                                </h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Birthdate <i className="fa fa-sort cursor-pointer" onClick={sortTable.bind(this, SORT_OPTIONS.BIRTH_DATE)} aria-hidden="true"></i></h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-center">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">Gender <i className="fa fa-sort cursor-pointer" onClick={sortTable.bind(this, SORT_OPTIONS.GENDER)} aria-hidden="true"></i></h5>
                                                </div>
                                                <div className="p-2.5 xl:p-5 flex items-center justify-left">
                                                    <h5 className="text-sm font-medium text-zinc-400 uppercase xsm:text-base">status <i className="fa fa-sort cursor-pointer" onClick={sortTable.bind(this, SORT_OPTIONS.STATUS)} aria-hidden="true"></i></h5>
                                                </div>

                                            </div>
                                            {/* content of the table*/}
                                            {/* first row */}
                                            {users?.length > 0 && users.map((user, i) => {
                                                return (
                                                    <div className="relative">
                                                        <div className="grid grid-cols-8 rounded-sm sm:grid-cols-8" key={i} style={{ opacity: !user.enabled ? '0.2' : '' }}>
                                                            <div className="flex items-center justify-left p-2.5 xl:p-5 text-zinc-400">
                                                                <div className=" flex items-center">
                                                                    <div className="flex items-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="icon-checkbox cursor-pointer"
                                                                            defaultValue={user.id}
                                                                            // defaultChecked={ selectedUsers.includes(user.id) ? true : false } 
                                                                            onChange={selectUser}
                                                                            checked={selectedUsers.includes(user.id) ? true : false}
                                                                        /></div>
                                                                    <div className="flex pl-4 items-center">
                                                                        <img src={user.images?.profile_picture_thumb || defaultProfileImage} className='h-9 w-9 rounded-xl' />
                                                                        <p className='text-xs pl-2 text-regal-blue font-bold '><NavLink to={`/user/profile/${user.id}`} >{user.fname + ' ' + user.lname}</NavLink></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400 font-medium">
                                                                <p className='text-xs text-ellipsis-email'>{user.username}</p>
                                                            </div>

                                                            <div className="flex items-center justify-center p-1 xl:p-2 text-zinc-400 font-bold">
                                                                <p className='text-xs text-ellipsis-email'>{user.email}</p>
                                                            </div>
                                                            <div className="flex items-center justify-center p-1 xl:p-2 text-zinc-400 font-bold">
                                                                <p className='text-xs text-ellipsis-email'>{
                                                                    user.role.toUpperCase()
                                                                }</p>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400 font-bold">
                                                                <p className='text-xs'>{moment(user.created_at).format('MM/DD/YYYY')}</p>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400 font-bold">
                                                                <p className='text-xs'>{moment(user.date_of_birth).format('MM/DD/YYYY')}</p>
                                                            </div>
                                                            <div className="flex items-center justify-center p-2.5 xl:p-5 text-zinc-400 font-medium">
                                                                <p className='text-xs'>{user.gender.toUpperCase()}</p>
                                                            </div>
                                                            <div className="btn-group flex items-center justify-between p-2.5 xl:p-5 text-zinc-400 font-bold">
                                                                {/* <p className='text-xs'><span><i className="fa fa-circle icon-circle"></i></span>{user.active ? 'Verified' : 'Not Verified'}</p> */}
                                                                <p className='text-xs'>{user.active ? (<span><i className="fa fa-circle text-green-600 pr-3"></i>Verified</span>) : (<span><i className="fa fa-circle text-red-600 pr-3"></i>Not Verified</span>)}</p>
                                                                <p>
                                                                    <span>
                                                                        <i
                                                                            className="fa fa-ellipsis-v pl-3 cursor-pointer"
                                                                            id={`user-option-${user.id}`}
                                                                            onClick={showUserOption}
                                                                        >
                                                                        </i>
                                                                    </span>
                                                                </p>
                                                            </div>

                                                        </div>

                                                        {
                                                            userOption == user.id ?
                                                                <div className="btn">
                                                                    <div className="flex flex-col">
                                                                        <NavLink to={`/user/edit/${user.id}?returnTo=${queryParams.page}`} className="btn-edit px-3 py-1 text-xs bg-white hover:bg-gray-100 text-zinc-400 font-medium cursor-pointer" >
                                                                            <button>
                                                                                Edit
                                                                            </button>
                                                                        </NavLink>

                                                                        {/* <button className="btn-edit px-3 py-1 text-xs bg-white hover:bg-gray-100 text-zinc-400 font-medium cursor-pointer">
                                                                                Edit
                                                                            </button> */}
                                                                        <button className="btn-enable px-3 py-1 text-xs bg-white hover:bg-gray-100 text-zinc-400 font-medium cursor-pointer" onClick={enableDisableUser.bind(this, user.id, user.enabled)}>
                                                                            {user.enabled ? 'Disable' : 'Enable'}
                                                                        </button>
                                                                    </div>
                                                                </div> : ''
                                                        }
                                                    </div>
                                                )
                                            })}

                                            {/* second row */}


                                        </div>



                                        {/* Pagination */}
                                        <div className="flex justify-end p-4 md:p-6 2xl:p-10">
                                            <div className="pagination-nav">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={<span><i className="fa fa-angle-right"></i></span>}
                                                    onPageChange={handlePageClick}
                                                    pageCount={Math.ceil(totalCount / queryParams.limit)}
                                                    previousLabel={<span><i className="fa fa-angle-left"></i></span>}
                                                    forcePage={queryParams.page-1}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Users