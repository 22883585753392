import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Footer from "../Footer"
import Aside from "../Aside"
import Header from "../Header"
import axios from "axios";
import UserPostItems from "./UserPostsItem";
import moment from "moment";
import Swal from "sweetalert2";
import { defaultImage } from "../../helpers/DefaultPostImage";
import Loader from "../Loader";
import defaultProfileImage from '../../assets/imgs/noimage.png'
import BackButton from "../BackButton";

const Post = () => {

    const { id } = useParams();
    const navigation = useNavigate();

    const [postData, setPostData] = useState({})
    const [showLoader, setShowLoader] = useState(false)

    const getPost = async (id) => {
        setShowLoader(true)
        try {
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/view-post/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            console.log('view post', response.data.data[0]);
            setPostData(response.data.data[0])
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        getPost(id)
    }, [])

    const deleteEvent = async (eventId, userId) => {
        if (eventId) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setShowLoader(true)
                    try {
                        const accessToken = sessionStorage.getItem('session')
                        const response = await axios.delete(`${process.env.REACT_APP_BASEURL}/api/admin/remove-post/${eventId}`, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });
                    }
                    catch (err) {
                        console.log(err);
                    }
                    finally {
                        setShowLoader(false)
                    }
                    navigation(`/user/profile/${userId}`);
                }
            });
        }
    }

    if (showLoader) {
        <Loader />
    } else {
        return (
            <React.Fragment>
                <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                    <Aside />
                    <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                        <Header />
                        <div>
                            <BackButton />
                        </div>
                        <div className="max-w-screen-2xl p-4 md:p-6 2xl:p-10 pb-2">
                            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                                <div className="col-span-12">
                                    <div className={""}>
                                        <div className="mb-9 max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-5 py-5 pt-6 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-5">
                                            <div className="">
                                                <div className="flex items-center mb-9 justify-between">
                                                    <div className="flex items-center">
                                                    <div className="h-16 w-16 mr-4"><img src={postData.user?.image || defaultProfileImage} className="rounded-full" /></div>
                                                    <div className="flex-col">
                                                        <div className="text-md font-semibold text-indigo-900"><NavLink to={`/user/profile/${postData.user?.id}`}>{postData.user?.name}</NavLink></div>
                                                        <div>
                                                            <p className="text-sm text-default-gray">
                                                                Shared with {postData.visibility == 'public' ?
                                                                    (<span><i className="fa fa-globe pr-1"></i>Public</span>) :
                                                                    (<span><i className="fa fa-users pr-1"></i>Friends</span>)} 
                                                                    {
                                                                        postData.event_date ? 
                                                                        <>
                                                                            &nbsp;on <span>{moment(postData.event_date).format('MM/DD/YYYY')}</span>
                                                                        </> : ''
                                                                    }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="" onClick={deleteEvent.bind(this, postData.id, postData.user?.id)}>
                                                    <i class="fa fa-trash icon-trash-large cursor-pointer text-xl rounded-md" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                {
                                                    postData?.post_items?.length ?
                                                        <div>
                                                            <UserPostItems postItems={postData?.post_items} />
                                                        </div> : 
                                                        <div>
                                                            <img className="mx-auto block w-3/12" src={defaultImage(postData.event_category)} />
                                                        </div>
                                                }
                                                <h5 className="text-center text-lg font-semibold mt-10">{postData.event_title}</h5>
                                                <p className="mt-6">
                                                    {
                                                        postData?.description
                                                    }
                                                </p>
                                                {/* <div className="mt-1"><NavLink to={`/user/view-event/${postData.id}`} className="text-sm font-semibold text-indigo-900">View Post</NavLink></div> */}
                                                <div className="mt-6 text-sm text-default-gray">
                                                    <a href="#"><span><i className="fa fa-thumbs-o-up mr-2"></i></span>{postData.likes} Like</a>
                                                    <a href="#"><span><i className="fa fa-comment ml-2 mr-2"></i></span>{postData.comments?.length || 0} Like</a>
                                                </div>
    
                                                {
                                                    postData?.comments?.map((comment, i) => {
                                                        return (
                                                            <div className="mt-10">
                                                                <div className="flex items-center mb-5">
                                                                    <div className="h-8 w-8 mr-4"><img src={comment.user?.image || defaultProfileImage} className="rounded-full" /></div>
                                                                    <div className="flex-col">
                                                                        <div className="text-md font-semibold text-black-700"><NavLink to={`/user/profile/${comment.user?.id}`}>{comment.user?.name}</NavLink></div>
                                                                        <div>
                                                                            <p className="text-sm text-default-gray">
                                                                                { comment.comment } ( { moment(comment.created_at).format('MMM DD YYYY') } )
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Post