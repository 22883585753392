import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import defaultProfileImage from '../assets/imgs/noimage.png'

const Header = () => {

    const routes = [
        { path: "/users", breadcrumb: "/users" },
        { path: "/example", breadcrumb: "Custom Example" },
    ];
    const breadcrumbs = useBreadcrumbs(routes);

    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const [adminUserData, setAdminUserData] = useState({})

    useEffect(() => {
        adminData()
    }, [])
    
    const adminData = async () => {
        const accessToken = sessionStorage.getItem('session')
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/master`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        setAdminUserData(response.data.data[0])
    }

    const logout = async () => {
        try {
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/logout`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            sessionStorage.removeItem('session')
            window.location.href = '/'
        } catch (error) {
            
        }
    }
    
    return (
        <>
            {/* Header */}
            <header className='sticky top-0 flex w-full bg-ligher-gray z-10'>
                <div className='flex flex-grow items-center justify-end px-4 py-4 shadow-2 md:px-6 2xl:px-11'>
                    <div></div>
                    <div className='relative'>
                        <div className="flex items-center gap-3 2xsm:gap-7">
                            <NavLink className="flex text-indigo-900 text-base font-bold">{ adminUserData.fname } {adminUserData.lname}</NavLink>
                            <img src={adminUserData.images?.profile_picture_thumb || defaultProfileImage } className="w-10 h-10 rounded-full" />
                            <div onClick={() => setIsOpen((state) => !state)}><span className="text-2xl cursor-pointer text-indigo-900">{isOpen ? (<i className="fa fa-angle-down"></i>) : (<i className="fa fa-angle-down"></i>)}</span></div>
                        </div>
                        {isOpen && <div className="absolute right-0 mt-1 flex w-full flex-col bg-white shadow-default">
                            <ul className="flex flex-col">
                                <li className="border-b border-stroke hover:bg-indigo-900 transition ease-in-out delay-100">
                                    <NavLink className="px-6 py-2 flex items-center flex text-zinc-700 font-medium text-sm hover:text-white pt-4 pb-3"
                                        to={`/user/profile/${adminUserData.id}`}>
                                        <span className='pr-3'><i className="fa fa-user"></i></span>Profile</NavLink>
                                </li>
                                <li className="border-b border-stroke hover:bg-indigo-900 transition ease-in-out delay-100">
                                    <a className="px-6 py-2 flex items-center flex text-zinc-700 font-medium text-sm hover:text-white pb-3 pt-4"
                                        href="/settings">
                                        <span className='pr-3'><i className="fa fa-cog"></i></span>Settings</a>
                                </li>
                            </ul>
                            <button 
                                onClick={logout}
                                className="flex items-center px-6 py-2 flex hover:bg-indigo-900 transition ease-in-out delay-100 border-b flex text-zinc-700 font-medium text-sm hover:text-white">
                                <span className='pr-3'><i className="fa fa-sign-out"></i></span>Log Out</button>
                            <div className="text-xs flex items-center gap-3.5 px-6 py-3 bg-default-gray text-white">Last Login: { moment(adminUserData?.online[0].login_time, 'YYYYMMDD').fromNow() }</div>
                        </div>
                        }

                    </div>
                </div>
            </header>
        </>
    )
}
export default Header