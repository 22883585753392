const Footer = ()=>{
    return(
        <div className="flex justify-between py-5 p-4 md:p-6 2xl:p-10">
        {/* <div className="text-sm font-medium xsm:text-base text-zinc-400">
            <p>Copyright © 2021 Soft by Creative Team.</p>
        </div>
        <div >
            <ul className="flex">
                <li className="px-4 text-sm font-medium xsm:text-base text-zinc-400 cursor-pointer">Creative Team</li>
                <li className="px-4 text-sm font-medium xsm:text-base text-zinc-400 cursor-pointer">About us</li>
                <li className="px-4 text-sm font-medium xsm:text-base text-zinc-400 cursor-pointer">Blog</li>
            </ul>
        </div> */}
        </div>
    )
}
export default Footer