import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Aside from "../Aside";
import Header from "../Header";
import axios from "axios";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import UserPostItems from "./UserPostsItem";
import defaultCover from '../../assets/imgs/default-cover.jpg';
import { defaultImage } from "../../helpers/DefaultPostImage";
import facebook from '../../assets/imgs/facebook.png';
import instagram from '../../assets/imgs/instagram.png';  
import linkedin from '../../assets/imgs/linkedin.png';  
import tiktok from '../../assets/imgs/tiktok.png';  
import twitter from '../../assets/imgs/twitter.png';  
import youtube from '../../assets/imgs/youtube.png';  
import chat from '../../assets/imgs/chat.png';  
import { getSocialLink } from "../../helpers/SocialLinks";
import defaultProfileImage from '../../assets/imgs/noimage.png'
import BackButton from "../BackButton";

const Profile = () => {

    const [toggleState, setToggleState] = useState(1);
    const { id } = useParams();
    const [userData, setUserData] = useState({});

    const profileData = async (id) => {
        try {
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/user/details`, { user_id: id }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            console.log('profile user', response.data.data[0]);
            setUserData(response.data.data[0])
        }

        catch (err) {

        }
        finally {

        }
    }

    const toggleTab = (index) => {
        setToggleState(index)
        console.log(index)
    }

    useEffect(() => {
        profileData(id)
    }, [id])


    return (
        <React.Fragment>
            <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                <Aside />
                <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                    <Header />
                    <div>
                        <BackButton />
                    </div>
                    {/* cover picture  */}
                    <div className="w-full p-4 md:p-6 2xl:p-10">
                        <div className="">
                            <div className="cover-pic">
                                <img src={userData.cover_image || defaultCover} className="rounded-xl h-64 w-full object-cover" />
                                <div className="cover-pic-content w-full mx-auto">
                                    <div className="bg-white p-4 md:p-6 2xl:p-10 content rounded-xl flex justify-between items-center">
                                        <div className="profile flex items-center">
                                            <img src={userData.images || defaultProfileImage} className="mr-3 h-14 w-14 rounded-full" />
                                            <div className="flex-col">
                                                <h5 className="title">{userData.fname} {userData.lname}</h5>
                                                <p className="position">{userData.title || ""}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className={toggleState === 1 ? 'active-tabs shadow-xl transition ease-in-out duration-300' : 'tabs'} onClick={() => toggleTab(1)}>Timeline ({userData?.posts?.length || 0})</div>
                                            <div className={toggleState === 2 ? 'active-tabs shadow-xl transition ease-in-out duration-300' : 'tabs'} onClick={() => toggleTab(2)}>Friends ({userData?.friends?.length || 0})</div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>


                    <div className="max-w-screen-2xl p-4 md:p-6 2xl:p-10 pb-2 mt-9">
                        <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                            {/* Post and AD */}
                            <div className="col-span-12 xl:col-span-9">
                                {userData?.posts?.length > 0 && userData.posts.map((post, i) => {
                                    return (
                                        <div className={toggleState === 1 ? 'tab-content active-content' : 'tab-content'}>
                                            <div className="mb-9 max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-5 py-5 pt-6 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-5">
                                                <div className="">
                                                    <div className="flex items-center mb-9">
                                                        <div className="h-16 w-16 mr-4"><img src={userData.images || defaultProfileImage} className="rounded-full" /></div>
                                                        <div className="flex-col">
                                                            <div className="text-md font-semibold text-indigo-900"><a href="">{userData.fname + ' ' + userData.lname}</a></div>
                                                            <div>
                                                                <p className="text-sm text-default-gray">
                                                                    Shared with {post.visibility == 'public' ? 
                                                                    (<span><i className="fa fa-globe pr-1"></i>Public</span>) : 
                                                                    (<span><i className="fa fa-users pr-1"></i>Friends</span>)} 
                                                                    {
                                                                        post.event_date ? 
                                                                        <>
                                                                            &nbsp;on <span>{moment(post.event_date).format('MM/DD/YYYY')}</span>
                                                                        </> : ''
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        post?.images?.length ?
                                                            <div>
                                                                <UserPostItems postItems={post.images} />
                                                            </div> : 
                                                            <div>
                                                            <img className="mx-auto block w-3/12" src={defaultImage(post.event_category)} />
                                                            </div>
                                                    }
                                                    <div className="mt-1"><NavLink to={`/user/view-event/${post.id}`} className="text-sm font-semibold text-indigo-900">
                                                        <h5 className="text-center text-anchor text-lg font-semibold mt-10">{post.event_title || 'View Post'}</h5>
                                                    </NavLink></div>
                                                    <div className="mt-6 text-sm text-default-gray">
                                                        <a href=""><span><i className="fa fa-thumbs-o-up mr-2"></i></span>{post.likes} Likes</a>
                                                        <a href=""><span><i className="fa fa-comment ml-2 mr-2"></i></span>{post.comments} Comments</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {/* Friends */}
                                <div className="col-span-12 xl:col-span-9">
                                    <div className={toggleState === 2 ? 'tab-content active-content' : 'tab-content'}>
                                        <div className="mb-9 max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-5 py-5 pt-6 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-5">
                                            <div className="cotainer">
                                                <div className="row mb-9">
                                                    {userData?.friends?.length > 0 && userData.friends.map((friend, i) => {
                                                        return (
                                                            <div className="card border px-9 py-7 shadow-md text-center border-sky-200">
                                                                <div className="">
                                                                    {
                                                                        <img src={friend.image || defaultProfileImage} className="h-16 w-16 rounded-full" />
                                                                    }
                                                                </div>
                                                                <div className="pt-5 text-regal-blue font-semibold text-md border-b">
                                                                    {
                                                                        friend.name
                                                                    }
                                                                </div>
                                                                <div className="pt-2 text-regal-blue font-medium text-sm">
                                                                    {
                                                                        friend.username
                                                                    }
                                                                </div>
                                                                <div className="pt-2 text-regal-blue font-medium text-sm">
                                                                    {
                                                                        friend.city || ''
                                                                    }
                                                                </div>

                                                                <div className="pt-5 font-medium text-sm butn">
                                                                   <NavLink to={`/user/profile/${friend.id}`}><span className="rounded-md px-3 py-2  bg-gradient"> View Profile</span></NavLink>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            {/* About me section */}
                            <div className="col-span-12 xl:col-span-3">
                                <div className="max-sm:overflow-x-scroll rounded-lg border border-stroke bg-white px-5 py-5 pt-6 shadow-xl dark:border-strokedark sm:px-7.5 xl:pb-5">
                                    <div className="about-section">
                                        <h4 className="border-b mb-6 text-lg font-semibold text-indigo-900 border-indigo-900 hover:border-dotted border-b-2">About Me</h4>
                                        <div>
                                            <p className="title mb-4"><i className="fa fa-book text-icon-color text-sm pr-2"></i> <span className="border-b border-b-2">Personal Details</span></p>
                                            <p className="subtitle">Gender : <span className="text-common-gray font-normal">{userData.gender}</span></p>
                                            <p className="subtitle">Birthday : <span className="text-common-gray font-normal">{userData.date_of_birth}</span></p>
                                        </div>
                                    </div>

                                    <div className="border-b mt-9 border-indigo-900"></div>

                                    <div className="about-section mt-9">
                                        <p className="title mb-4"><i className="fa fa-map-marker text-icon-color text-sm pr-2"></i> <span className="border-b border-b-2">Location</span></p>
                                        <p className="subtitle">Hometown : <span className="text-common-gray font-normal">{userData.address_details?.hometown}</span></p>
                                        <p className="subtitle">City : <span className="text-common-gray font-normal">{userData.address_details?.city}</span></p>
                                        {/* <p className="subtitle">State : <span className="text-common-gray font-normal">Los </span></p>
                                        <p className="subtitle">Country : <span className="text-common-gray font-normal">Los </span></p> */}
                                    </div>

                                    <div className="border-b mt-9 border-indigo-900"></div>

                                    <div className="about-section mt-9">
                                        <p className="title mb-4"><i className="fa fa-user text-icon-color text-sm pr-2"></i> <span className="border-b border-b-2">Accounts Linked</span></p>
                                        <div className="flex flex-wrap social-icons">
                                            {userData?.social_media_accounts?.facebook ? (<a href={getSocialLink('facebook', userData.social_media_accounts.facebook)} target="_blank"><img src={facebook} /></a>) : (<img src={facebook} className="opacity-30"/>)}
                                            {userData?.social_media_accounts?.instagram ? (<a href={getSocialLink('instagram', userData.social_media_accounts.instagram)} target="_blank"><img src={instagram} /></a>) : (<img src={instagram} className="opacity-30"/>)}
                                            {userData?.social_media_accounts?.linkedin ? (<a href={getSocialLink('linkedin', userData.social_media_accounts.linkedin)} target="_blank"><img src={linkedin} /></a>) : (<img src={linkedin} className="opacity-30"/>)}
                                            {userData?.social_media_accounts?.tiktok ? (<a href={getSocialLink('tiktok', userData.social_media_accounts.tiktok)} target="_blank"><img src={tiktok} /></a>) : (<img src={tiktok} className="opacity-30"/>)}
                                            {userData?.social_media_accounts?.twitter ? (<a href={getSocialLink('twitter', userData.social_media_accounts.twitter)} target="_blank"><img src={twitter} /></a>) : (<img src={twitter} className="opacity-30"/>)}
                                            {userData?.social_media_accounts?.youtube ? (<a href={getSocialLink('youtube', userData.social_media_accounts.youtube)} target="_blank"><img src={youtube} /></a>) : (<img src={youtube} className="opacity-30"/>)}
                                            {userData?.social_media_accounts?.chat ? (<a href={getSocialLink('twitch', userData.social_media_accounts.twitch)} target="_blank"><img src={chat} /></a>) : (<img src={chat} className="opacity-30"/>)}
                                        </div>
                                    </div>

                                    <div className="border-b mt-9 border-indigo-900"></div>

                                    <div className="about-section mt-9">
                                        <p className="title mb-4"><i className="fa fa-envelope text-icon-color text-sm pr-2"></i> <span className="border-b border-b-2">Referrals</span></p>
                                        <p className="subtitle">
                                            <ul>
                                                <li>Code : <span className="text-common-gray font-normal">{userData.invites?.invite_code}</span></li>
                                                <li>Referrals Sent : <span className="text-common-gray font-normal">{userData.invites?.referrals_sent}</span></li>
                                                <li>User Registered : <span className="text-common-gray font-normal">{userData.invites?.users_joined}</span></li>
                                                <li>Joined Via : <span className="text-common-gray font-normal">{userData.joined_via}</span></li>
                                            </ul>
                                        </p>
                                    </div>

                                </div>



                            </div>
                        </div>


                    </div>
                </div>

                <Footer />
            </div>


        </React.Fragment>
    )
}
export default Profile