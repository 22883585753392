import { NavLink } from 'react-router-dom'
import { useState } from 'react'

const Aside = () => {
    const SIDEBAR_DEFAULT_INDEX = 0
    const [sidebarIndex, setSidebarIndex] = useState(SIDEBAR_DEFAULT_INDEX);

    const handleToggle = (index) => {
        if (index == sidebarIndex) {
            setSidebarIndex(SIDEBAR_DEFAULT_INDEX);
        } else {
            setSidebarIndex(index);
        }
    }
    return (

        <aside className="w-1/5 left-0 top-0 z-9999 flex h-screen flex-col overflow-y-hidden bg-white border lg:static rounded-lg">
            <div className='flex items-center justify-between gap-2.5 px-6 py-2'>
                <div className='px-4 block mx-auto w-3/4 mt-10'>
                    <NavLink
                        to={"/"}>
                        <span className='flex text-regal-blue font-semibold text-sm flex items-center'>
                            <img src='../../timelyne-logo.png'/>
                        </span>
                    </NavLink>
                </div>
            </div>


            <div className='no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear'>
                <nav className='py-4 px-4 lg:mt-4 lg:px-6'>
                    <div>
                        <ul className='mb-6 flex flex-col gap-3.5'>
                            <li>
                                <div className="group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-semibold"
                                    href="/">
                                    <div className="bg-zinc-200 w-7 h-7 rounded-md shadow-xl relative">
                                        <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-black">
                                            <i className="fa fa-dashboard text-icon-color text-sm"></i>
                                        </div>
                                    </div>
                                    <NavLink
                                        to={"/"}>
                                        <span className='flex text-default-gray font-semibold text-sm'>Dashboard</span>
                                    </NavLink>
                                </div>
                            </li>

                            <li className=''>
                                <div onClick={handleToggle.bind(this, 1)} className="cursor-pointer group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-semibold"
                                    href="/">
                                    <div className="bg-zinc-200 w-7 h-7 rounded-md shadow-xl relative">
                                        <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-black">
                                            <i className="fa fa-users text-icon-color text-sm"></i>
                                        </div>
                                    </div>
                                    <span className='flex text-default-gray font-semibold text-sm'>Members</span>
                                    <span className='text-lg cursor-pointer'> {sidebarIndex == 1 ? (<i className="fa fa-angle-up text-default-gray font-bold"></i>) : (<i className="fa fa-angle-down text-default-gray font-bold"></i>)}</span>
                                </div>
                                {sidebarIndex == 1 && <div className="translate transform overflow-hidden false text-xs">
                                    <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-9 font-medium text-sm">
                                        <li>
                                            <NavLink
                                                to={"/users?page=1"}
                                                className="group relative flex items-center gap-2.5 rounded-md px-4 text-default-gray" href="/">
                                                <span><i className="fa fa-circle icon-circle"></i></span>Users
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>}
                            </li>

                            <li>
                                <div onClick={handleToggle.bind(this, 2)} className="cursor-pointer group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-semibold"
                                    href="/">
                                    <div className="bg-zinc-200 w-7 h-7 rounded-md shadow-xl relative">
                                        <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-black">
                                            <i className="fa fa-usersfa fa-newspaper-o text-icon-color text-sm"></i>
                                        </div>
                                    </div>
                                    <span className='flex text-default-gray font-semibold text-sm'>Content</span>
                                    <span className="cursor-pointer text-lg">{sidebarIndex == 2 ? (<i className="fa fa-angle-up text-default-gray font-bold"></i>) : (<i className="fa fa-angle-down text-default-gray font-bold"></i>)}</span>
                                </div>
                                {sidebarIndex == 2 && <div className="translate transform overflow-hidden false text-xs">
                                    <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-9 font-semibold text-sm">
                                        <li className='mb-3'>
                                            <NavLink
                                                to={"/system-message"}
                                                className="group relative flex items-center gap-2.5 rounded-md px-4 font-medium " href="/">
                                                <div className='text-default-gray'><span><i className="fa fa-circle icon-circle"></i></span>System Messages</div>
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                to={"/news?page=1"}
                                                className="group relative flex items-center gap-2.5 rounded-md px-4 font-medium" href="/">
                                                <div className='text-default-gray'><span><i className="fa fa-circle icon-circle"></i></span>Story Headline</div>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>}
                            </li>

                            <li>
                                <div onClick={handleToggle.bind(this, 3)} className="cursor-pointer group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-semibold"
                                    href="/">
                                    <div className="bg-zinc-200 w-7 h-7 rounded-md shadow-xl relative">
                                        <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-black">
                                            <i className="fa fa-map-o text-xs font-bold text-icon-color text-sm"></i>
                                        </div>
                                    </div>
                                    <span className='flex text-default-gray font-semibold text-sm'>Monetization</span>
                                    <span className="cursor-pointer text-lg">{sidebarIndex == 3 ? (<i className="fa fa-angle-up text-default-gray font-bold"></i>) : (<i className="fa fa-angle-down text-default-gray font-bold"></i>)}</span>
                                </div>
                                {sidebarIndex == 3 && <div className="translate transform overflow-hidden false text-xs">
                                    <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-10 font-medium text-sm">
                                        <li className='mb-3'>
                                            <NavLink
                                                to={"/advertisement?page=1"}
                                                className="group relative flex items-center gap-2.5 rounded-md px-4 font-medium" href="/advertisement">
                                                <div className='text-default-gray'><span><i className="fa fa-circle icon-circle"></i></span>Advertising</div>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>}
                            </li>

                        </ul>
                    </div>
                </nav>
            </div>
        </aside>
    )
}
export default Aside