import React, { useState } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/customized-slider.css'
// import tt from '../../assets/img/logo-tt.png'

export default function UserPostItems({ postItems, albumId, toggleAlbum }) {

    const settings = {
        dots: true,
        infinite : false,
        arrows:false,
        afterChange : () => {
            const videos = document.getElementsByTagName('video')
            for (let video of videos) {
                video.pause()
                video.currentTime = 0
            }
        }
    }

    return (
        <Slider {...settings}>
            {
                postItems.map((item, index) => {
                    return (
                        <div key={index} style={{ height : '438px' }}>
                            {
                                item.type === 'image' ?
                                <img className="d-block w-100 rounded-xl" src={item.post_item} alt="" /> : 
                                <video className="d-block w-100" controls key={item.post_item}>
                                    <source src={item.post_item}></source>
                                </video>
                            }  
                        </div>
                    )
                })
            }
        </Slider>
    )

}