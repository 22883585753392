import React from "react"
import { useNavigate } from "react-router-dom"

const BackButton = () => {

    const navigate = useNavigate();

    return (
        <button className="ml-8" onClick={() => navigate(-1)}><i class="fa fa-angle-left" aria-hidden="true"></i> Back</button>
    )
}

export default BackButton;