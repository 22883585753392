import React, { useState, useEffect } from "react"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import Footer from "../Footer"
import Aside from "../Aside"
import Header from "../Header"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios"
// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from "react-phone-number-input"
import Loader from "../Loader"
import moment from "moment"
import BackButton from "../BackButton"
// import 'react-phone-number-input/style.css'

const EditUser = (props) => {

    const { id, returnTo } = useParams();
    const [query] = useSearchParams();
    const navigate = useNavigate();

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [user, setUser] = useState({});
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);

    const changeDateOfBirth = (date) => {
        setDateOfBirth(moment(date).format('YYYY-MM-DD'))
    }

    useEffect(() => {
        getUserDetails(id)
    }, [])

    const getUserDetails = async (id) => {
        const accessToken = sessionStorage.getItem('session')
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/user/${id}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        setUser(response.data.data)
        setDateOfBirth(moment(response.data.data.date_of_birth).format('YYYY-MM-DD'))
    }

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        })
    }

    const handlePhone = (number) => {
        if (number != undefined) {
            if (isValidPhoneNumber(number)) {
                setUser({
                    ...user,
                    phone_number: number
                })
                setErrors({
                    ...errors,
                    phone_number: null
                })
            } else {
                setErrors({
                    phone_number: "Invalid phone number"
                })
            }
        }
    }

    const updateUser = async (e) => {
        e.preventDefault()
        try {
            setShowLoader(true)
            const payload = {
                "fname": user.fname,
                "lname": user.lname,
                "username": user.username,
                "phone_number": user.phone_number,
                "gender": user.gender,
                "date_of_birth": dateOfBirth,
                "city": user?.city || user.address?.city || null,
                "hometown": user?.hometown || user.address?.hometown || null,
            }
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.patch(`${process.env.REACT_APP_BASEURL}/api/admin/user/${id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            navigate(`/users?page=${query.get('returnTo')}`, {})
        } catch (error) {

        } finally {
            setShowLoader(false);
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>

                <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                    <Aside />
                    <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                        <Header />
                        <div>
                            <BackButton />
                        </div>
                        <div className="px-9 py-9">
                            <div className="mx-auto">
                                <div className="mt-5 bg-white rounded-lg shadow px-9 py-9">
                                    <div class="w-4/5 mx-auto">
                                        {/* column 1 */}
                                        <div className="">
                                            <form onSubmit={updateUser} className="flex justify-around">

                                                <div className="">
                                                    <div className="flex flex-col ">
                                                        <div className="mb-2 text-2xl font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text">Basic Details</div>
                                                    </div>
                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="fname" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                First Name
                                                            </label>
                                                        </div>
                                                        <input
                                                            name="fname"
                                                            defaultValue={user.fname}
                                                            onChange={handleChange}
                                                            className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />

                                                    </div>

                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="lname" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Last Name
                                                            </label>
                                                        </div>
                                                        <input
                                                            name="lname"
                                                            onChange={handleChange}
                                                            defaultValue={user.lname}
                                                            className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />

                                                    </div>

                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="username" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Username
                                                            </label>
                                                        </div>
                                                        <input
                                                            name="username"
                                                            onChange={handleChange}
                                                            defaultValue={user.username}
                                                            className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />

                                                    </div>

                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="number" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Phone Number
                                                            </label>
                                                        </div>
                                                        <PhoneInput
                                                            placeholder="Enter phone number"
                                                            value={user.phone_number}
                                                            onChange={handlePhone}
                                                            country="US"
                                                            className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"
                                                        // style={"pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"}
                                                        />
                                                        {
                                                            errors?.phone_number ?
                                                                <span>
                                                                    <br />
                                                                    <small className="text-red">{errors?.phone_number}</small>
                                                                </span> : ''
                                                        }
                                                        {/* <input
                                                            name="number"
                                                            onChange={handleChange}
                                                            defaultValue={user.phone_number}
                                                            className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" /> */}

                                                    </div>

                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="dob" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Date of Birth
                                                                {/* <Calendar /> */}
                                                                {/* <input 
                                                        className="text-zinc-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"/> */}
                                                            </label>
                                                        </div>
                                                        <div className="date-picker-style mt-2">
                                                            <DatePicker
                                                                selected={dateOfBirth}
                                                                onChange={changeDateOfBirth}
                                                                minDate={"1800-01-01"}
                                                                showIcon
                                                                isClearable
                                                                maxDate={"2011-12-31"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="pt-5 text-zinc-700 py-2.5 mt-2 text-base flex justify-between">
                                                        <div className="mt-2">
                                                            <input
                                                                className="cursor-pointer"
                                                                type="radio"
                                                                name="gender"
                                                                checked={user.gender == 'male' ? "checked" : ""}
                                                                value="male"
                                                                onChange={handleChange}
                                                            /> Male
                                                        </div>

                                                        <div className="mt-2">
                                                            <input className="cursor-pointer"
                                                                type="radio"
                                                                name="gender"
                                                                checked={user.gender == 'female' ? "checked" : ""}
                                                                value="female"
                                                                onChange={handleChange}
                                                            /> Female
                                                        </div>

                                                        <div className="mt-2">
                                                            <input className="cursor-pointer"
                                                                type="radio"
                                                                name="gender"
                                                                checked={user.gender == 'other' ? "checked" : ""}
                                                                value="other"
                                                                onChange={handleChange}
                                                            /> Other
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* colum 2 */}
                                                <div className="">
                                                    <div className="flex flex-col">
                                                        <div className="mb-2 text-2xl font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text">Address Details</div>
                                                    </div>
                                                    <div className="">
                                                        <div className="pt-5">
                                                            <div>
                                                                <label for="city" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                    City
                                                                </label>
                                                            </div>
                                                            <input
                                                                name="city"
                                                                defaultValue={user?.address?.city}
                                                                onChange={handleChange}
                                                                className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />

                                                        </div>
                                                        <div className="pt-5">
                                                            <div>
                                                                <label for="hometown" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                    Hometown
                                                                </label>
                                                            </div>
                                                            <input
                                                                name="hometown"
                                                                defaultValue={user?.address?.hometown}
                                                                onChange={handleChange}
                                                                className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />

                                                        </div>
                                                    </div>
                                                    <div className="pt-5">
                                                        <button type="submit" class="mt-5 px-9 cursor-pointer rounded-lg bg-blue-700 p-2 text-white gradient-bg text-sm">Update Account</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

export default EditUser