import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import axios from 'axios'

let chartInstance;

const LoginAndPostsChart = (props) => {

    const startYear = 2021;
    const currentYear = new Date().getFullYear();
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const MONTHS_COUNT = 12;
    const [selectedYear, setSelectedYear] = useState(currentYear)

    const fetchLineChartData = async (year) => {
        try {
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/dashboard/get-activities-count/${year}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const postsCount = response.data.postsMonthData;
            const usersCount = response.data.usersMonthData;
            const usersData = [];
            const postsData = [];
            for (let index = 0; index < MONTHS_COUNT; index++) {
                usersData.push({
                    month: MONTHS[index],
                    count: usersCount[index]
                })
                postsData.push({
                    month: MONTHS[index],
                    count: postsCount[index]
                })
            }
            return {
                usersData, postsData
            }
        } catch (error) {}
    }

    const loadLineChartData = async (year) => {
        const chartData = await fetchLineChartData(year);
        if (chartInstance) chartInstance.destroy();
        chartInstance = new Chart(
            document.getElementById('acquisitions'),
            {
                type: 'line',
                options: {
                    animation: true,
                    elements: {
                        line: {
                            tension: 0.4,
                        },
                    },
                },
                data: {
                    labels: MONTHS.map(m => m),
                    datasets: [
                        {
                            label: 'Posts Created',
                            data: chartData?.postsData?.map(row => row.count),
                            fill: 'start'
                        },
                        {
                            label: 'Users Login',
                            data: chartData?.usersData?.map(row => row.count),
                            fill: 'start'
                        },
                    ]
                }
            }
        );
    }

    const selectYear = async (e) => {
        setSelectedYear(e.target.value);
        await loadLineChartData(e.target.value);
    }

    useEffect(() => {
        loadLineChartData(selectedYear);
    }, [])

    return (
        <div>
            <div className="option-dropdown float-right mb-9">
                <select onChange={selectYear} className="login-post-optionSelect">
                    {
                        [...(Array.from(
                            { length: (currentYear - startYear) / 1 + 1}, 
                            (_, i) => startYear + (i * 1)
                        ))]
                        .reverse()
                        .map(y => (
                            <option value={y} key={`year-${y}`}>{y}</option>
                        ))
                    }
                </select>
            </div>
            <canvas id="acquisitions" className="wh-full"></canvas>
        </div>
    )
}

export default LoginAndPostsChart;