import React from "react"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, FontFamily, FontSize, FontColor, FontBackgroundColor, Strikethrough, Subscript, Superscript, Code, Link, ImageUpload, Base64UploadAdapter, ImageInsert, ImageToolbar, ImageCaption, ImageTextAlternative, CKBoxImageEdit, ImageBlock, BlockQuote, CodeBlock, List, TodoList, Indent, Alignment, PictureEditing, ImageEditing, ImageInline, ImageResize, ImageUtils, ImageStyle, LinkImage, SimpleUploadAdapter } from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import "../assets/css/custom-ckeditor.css"

const CustomCkEditor = (props) => {
    const session = sessionStorage.getItem('session')

    const onChange = (event, editor) => {
        props.onChange({ value: editor.getData(), name: props.name })
    }

    return (
        <CKEditor
            onChange={onChange}
            editor={ClassicEditor}
            config={{
                toolbar: {
                    items: [
                        'undo', 'redo',
                        '|',
                        'heading',
                        '|',
                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                        '|',
                        'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                        '|',
                        'alignment',
                        '|',
                        'link',
                        'insertImage',
                        'blockQuote', 'codeBlock',
                        '|',
                        'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                    ],
                    shouldNotGroupWhenFull: true
                },
                image: {
                    toolbar: [
                        'imageStyle:block',
                        'imageStyle:side',
                        '|',
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'linkImage'
                    ],
                    insert: {
                        // This is the default configuration, you do not need to provide
                        // this configuration key if the list content and order reflects your needs.
                        integrations: ['upload', 'assetManager', 'url'],
                        type: 'auto',
                    }
                },
                plugins: [
                    Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading,
                    FontFamily, FontSize, FontColor, FontBackgroundColor,
                    Strikethrough, Subscript, Superscript, Code, Link,
                    Image, ImageBlock, ImageInline, ImageInsert, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageEditing, LinkImage,
                    BlockQuote, CodeBlock, List, Alignment, TodoList, Indent,
                ],
                simpleUpload: {
                    // The URL that the images are uploaded to.
                    uploadUrl: `${process.env.REACT_APP_BASEURL}/api/admin/image/upload`,
        
                    // Enable the XMLHttpRequest.withCredentials property.
                    // withCredentials: true,
        
                    // Headers sent along with the XMLHttpRequest to the upload server.
                    headers: {
                        Authorization: `Bearer ${session}`
                    },
                },
                extraPlugins: [
                    SimpleUploadAdapter
                ],
                mention: {
                    // Mention configuration
                },
                initialData: props?.textData || null,
            }}
        />
    )
}

export default CustomCkEditor;