import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import moment from "moment";
import { titlecase } from "../../helpers/String";

let chartInstance;

const GrowthChart = (props) => {

    const RANGES = {
        TODAY: 'today',
        LAST_WEEK: 'week',
        LAST_MONTH: 'month',
        LAST_YEAR: 'year',
        CUSTOM: 'custom'
    }

    const GROWTH_TYPE = {
        USERS: 'users',
        LIFE_EVENTS: 'events',
        SUB_ACCOUNTS: 'subaccounts',
    }

    const startYear = 2021;
    const currentYear = new Date().getFullYear();
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [monthDays, setMonthDays] = useState([]);

    const getRowRange = (rangeType) => {
        //Generate range for today
        if (RANGES.TODAY == rangeType) {
            return [...(Array.from(
                { length: (moment().daysInMonth() - 1) / 1 + 1 },
                (_, i) => 1 + (i * 1)
            ))].map(m => m)

            //Generate range for last week
        } else if (RANGES.LAST_WEEK == rangeType) {
            const daysInDigit = [];
            for (let i = 1; i <= 7; i++) {
                daysInDigit.push(moment().subtract(i, 'day').format('MM/DD/YYYY'))
            }
            return daysInDigit.reverse();

            //Generate range for last month
        } else if (RANGES.LAST_MONTH == rangeType) {
            const daysInDigit = [
                moment().format('DD, MM Y')
            ];
            const daysDiff = moment(props.customDateRange.endDate).diff(props.customDateRange.startDate, 'days')
            for (let i = 0; i <= daysDiff; i++) {
                daysInDigit.push(moment().subtract(i, 'day').format('MM/DD/YYYY'))
            }
            return daysInDigit.reverse();

            //Generate range for last year
        } else if (RANGES.LAST_YEAR == rangeType) {
            const months = [];
            for (let i = 0; i <= 12; i++) {
                months.push(moment(props.customDateRange.startDate).add(i, 'months').format('MMM, YYYY'))
            }
            return months;

            //Generate range for custom range
        } else if (RANGES.CUSTOM == rangeType) {
            //Generate range if custom range is more than 1 year
            const yearsDiff = moment(props.customDateRange.endDate).diff(props.customDateRange.startDate, 'years')
            if (yearsDiff > 0) {
                const years = [];
                const startYear = moment(props.customDateRange.startDate).format('YYYY')
                const endYear = moment(props.customDateRange.endDate).format('YYYY')
                for (let i = startYear; i <= endYear; i++) {
                    years.push(i)
                }
                return years;
            }

            //Generate range if custom range is for months
            const monthsDiff = moment(props.customDateRange.endDate).diff(props.customDateRange.startDate, 'months')
            if (monthsDiff > 0) {
                const months = [];
                for (let i = 0; i <= monthsDiff; i++) {
                    months.push(moment(props.customDateRange.startDate).add(i, 'months').format('MMM, YYYY'))
                }
                return months;
            }

            //Generate range if custom range is for days
            const daysDiff = moment(props.customDateRange.endDate).diff(props.customDateRange.startDate, 'days')
            const daysInDigit = [];
            for (let i = 0; i <= daysDiff; i++) {
                daysInDigit.push(moment(props.customDateRange.startDate).add(i, 'day').format('DD'))
            }
            return daysInDigit;
        }
    }

    const getColor = (type) => {
        console.log(type)
        if (type == GROWTH_TYPE.USERS) {
            return 'rgb(255, 0, 128)';
        } else if(type == GROWTH_TYPE.LIFE_EVENTS) {
            return 'rgb(33, 82, 255)';
        } else if(type == GROWTH_TYPE.SUB_ACCOUNTS) {
            return 'rgb(255 71 33)';
        }
    }

    useEffect(() => {
        const chartData = props.customDateRange?.growthData || [];
        if (chartInstance) chartInstance.destroy();
        chartInstance = new Chart(
            document.getElementById('growth'),
            {

                type: 'line',
                options: {
                    animation: true,
                    elements: {
                        line: {
                            tension: 0.4,
                        },
                    },
                    scales: {
                        y: {
                            beginAtZero: true, // Set this to true to start the Y-axis at 0
                        },
                        x: {
                            // If you want to configure the X-axis, you can do it here
                        }
                    },
                    backgroundColor: getColor(props.customDateRange.growthType),
                
                },
                data: {
                    labels: getRowRange(props.customDateRange.type),
                    datasets: [
                        {
                            label: titlecase(props.customDateRange?.growthType),
                            data: props?.growthData?.map(val => val),
                            fill: 'start'
                        },
                    ]
                }
            }
        );
    }, [props.customDateRange.type])

    return (
        <div>
            <canvas id="growth" className="wh-full"></canvas>
        </div>
    )
}

export default GrowthChart;