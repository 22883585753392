import React, { useEffect, useState, useRef } from "react";
import Footer from "./Footer";
import Aside from "./Aside";
import Header from "./Header";
import moment from "moment";
import Loader from "./Loader";
import axios from "axios";
import Swal from "sweetalert2";

const Settings = () => {

    const [ showLoader, setShowLoader ] = useState(false)
    const [ lastPasswordChanged, setLastPasswordChanged ] = useState(null)
    const [ passwords, setPasswords ] = useState({})
    const [ passwordsErr, setPasswordsErr ] = useState({})

    useEffect(() => {
        getLastPasswordChangeTime()
    }, [])

    const getLastPasswordChangeTime = async () => {
        const accessToken = sessionStorage.getItem('session')
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/last-password-update`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        setLastPasswordChanged(
            response.data.password_updated_at ? 
            moment(response.data.password_updated_at).utcOffset(parseInt(sessionStorage.getItem('offset'))).format('MMMM Do YYYY, h:mm:ss a') : null
        )
    }

    const handleChange = (e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value,
        })
    }

    const updatePassword = async (e) => {
        e.preventDefault()

        if (passwords.newPassword !== passwords.confirmNewPassword) {
            setPasswordsErr({
                newPassword: "Password didn't match."
            })
            return;
        }

        try {
            setShowLoader(true)
            const payload = {
                "current_password": passwords.currentPassword,
                "password": passwords.newPassword,
                "password_confirmation": passwords.confirmNewPassword
            }
            const accessToken = sessionStorage.getItem('session')
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/update-password`, payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            sessionStorage.setItem('session', response.data.accessToken)
            setLastPasswordChanged(
                response.data.password_updated_at ?
                moment(response.data.password_updated_at).utcOffset(parseInt(sessionStorage.getItem('offset'))).format('MMMM Do YYYY, h:mm:ss a') : null
            )
            setShowLoader(false)
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Your password has been changed successfully",
                showConfirmButton: true,
                timer: 3000
            });
        } catch (error) {
            console.log(error.response.data.message)
            setPasswordsErr({
                currentPassword: error.response.data.message
            })
            setShowLoader(false)   
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
    
                <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                    <Aside />
                    <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                        <Header />
                        <div className="px-9 py-9">
                            <div className="mx-auto">
                                <div className="mt-5 bg-white rounded-lg shadow px-9 py-9">
                                    <div class="flex">
                                        {/* column 1 */}
                                        <div className="mx-auto w-3/12">
                                            <div className="flex flex-col ">
                                                <div className="mb-2 text-2xl font-bold gradient-text">Change Password</div>
                                            </div>
                                            <form onSubmit={updatePassword}>
                                                <div className="">
                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="currentPassword" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Current Password
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="password"
                                                            name="currentPassword"
                                                            onChange={handleChange}
                                                            className="w-full pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" 
                                                        />
                                                        {
                                                            passwordsErr?.currentPassword ? 
                                                            <p className='text-red-600 text-xs'>{passwordsErr.currentPassword}</p> : ''
                                                        }
                                                    </div>
    
                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="newPassword" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                New Password
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="password"
                                                            name="newPassword"
                                                            onChange={handleChange}
                                                            className="w-full pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" 
                                                        />
                                                        {
                                                            passwordsErr?.newPassword ? 
                                                            <p className='text-red-600 text-xs'>{passwordsErr.newPassword}</p> : ''
                                                        }
                                                    </div>
    
                                                    <div className="pt-5">
                                                        <div>
                                                            <label for="username" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Confirm New Password
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="password"
                                                            name="confirmNewPassword"
                                                            onChange={handleChange}
                                                            className="w-full pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
    
                                                    </div>

                                                    <div className="pt-5">
                                                        <button type="submit" class="mt-5 px-9 cursor-pointer rounded-lg bg-blue-700 p-2 text-white gradient-bg text-sm">Update Password</button>
                                                    </div>

                                                    <div className="pt-5">
                                                        {
                                                            lastPasswordChanged ? 
                                                            <div>
                                                                <p className="text-zinc-700 py-2.5 mt-2 text-sm"><strong>Last Password Updated At: {lastPasswordChanged}</strong></p>
                                                            </div> : ''
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div>
    
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
    
            </React.Fragment>
        )
    }
}

export default Settings;