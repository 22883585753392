export const getSocialLink = (social, account) => {
    const map = {
        facebook: 'https://facebook.com',
        instagram: 'https://instagram.com',
        linkedin: 'https://linkedin.com',
        tiktok: 'https://tiktok.com',
        twitter: 'https:/x.com',
        youtube: 'https://youtube.com',
        twitch: 'https://twitch.tv/'
    }

    return map[social]+'/'+account;
}