import React, { useState, useEffect } from "react"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import Footer from "../Footer"
import Aside from "../Aside"
import Header from "../Header"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import CustomCkEditor from "../CustomCkEditor"
import moment from "moment"
import axios from "axios"
import Loader from "../Loader"
import BackButton from "../BackButton"

const AddAdvertisement = (props) => {

    const { id } = useParams();
    const [ query ] = useSearchParams();
    const navigate = useNavigate();

    const [pageUpdate, setPageUpdate] = useState(true)
    const [createDate, setCreateDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [textDetails, setTextDetails] = useState({
        title: '',
        deal_notes: null,
        amount: null,
        url: '',
        status: 0,
    })
    const [imageData, setImageData] = useState({
        imageWidth: 0,
        imageHeight: 0,
        imagePath: '',
        imagePreview: null,
        imagePreviewWidth: 0,
        imagePrevieHeight: 0,
    })
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        id && fetchAd(id);
    }, [])

    const fetchAd = async (id) => {
        setPageUpdate(false)
        const accessToken = sessionStorage.getItem('session')
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/admin/edit-ad/${id}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        setTextDetails({
            title: response.data.data.title,
            deal_notes: response.data.data.deal_notes,
            amount: Number(response.data.data.amount),
            url: response.data.data.url,
            status: response.data.data.status,
        })
        setImageData({
            ...imageData,
            imagePreview: response.data.data.image,
            imagePreviewWidth: 200,
            imagePreviewHeight: 200,
        })
        setCreateDate(response.data.data.created_at)
        setStartDate(response.data.data.from_date)
        setEndDate(response.data.data.to_date)
        setPageUpdate(true)
    }

    const changeCreateDate = (date) => {
        setCreateDate(date)
    }

    const handTextDetails = (e) => {
        setTextDetails({
            ...textDetails, [e.target.name]: e.target.value,
        })
    }

    const uploadImage = (e) => {
        const image = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (event) => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                console.log(img.naturalHeight, img.naturalWidth)
                setImageData({
                    ...imageData,
                    imageHeight: img.naturalHeight,
                    imageWidth: img.naturalWidth,
                    imagePreview: URL.createObjectURL(image),
                    imagePreviewWidth: 200,
                    imagePreviewHeight: 200,
                    imagePath: image,
                })
            }
        }
    }

    const getTextEditorValue = ({ value }) => {
        console.log(value)
        setTextDetails({
            ...textDetails,
            deal_notes: value,
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            setShowLoader(true)
            const formData = new FormData;
            formData.append('created_at', moment(createDate).format("YYYY-MM-DD"))
            formData.append('from_date', moment(startDate).format("YYYY-MM-DD"))
            formData.append('to_date', moment(endDate).format("YYYY-MM-DD"))
            formData.append('title', textDetails.title)
            formData.append('amount', textDetails.amount)
            formData.append('url', textDetails.url)
            formData.append('deal_notes', textDetails.deal_notes)
            formData.append('status', textDetails.status)
            formData.append('imageWidth', imageData.imageWidth)
            formData.append('imageHeight', imageData.imageHeight)
            formData.append('image', imageData.imagePath)

            const accessToken = sessionStorage.getItem('session')
            let endpoint = 'create-ad';
            if (id) {
                endpoint = 'update-ad';
                formData.append('id', id);
            }
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/${endpoint}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (query.get('returnTo')) {
                navigate(`/advertisement?page=${query.get('returnTo')}`, {})
            } else {
                navigate(`/advertisement?page=1`, {})
            }
        } catch (error) {
            
        } finally {
            setShowLoader(false)
        }
    }

    if (showLoader) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                {
                    pageUpdate ?
                        <div className="flex h-screen overflow-hidden py-4 bg-ligher-gray">
                            <Aside />
                            <div className='w-3/4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-ligher-gray'>
                                <Header />
                                <div>
                                    <BackButton />
                                </div>
                                <div className="px-9 py-9">
                                    <div className="mx-auto">
                                        <div className="mt-5 bg-white rounded-lg shadow">
                                            <div class="px-9 py-9 w-3/4 mx-auto">
                                                <div className="flex flex-col">
                                                    {/* <div className="mb-2 text-xs font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text cursor-pointer"><i className="fa fa-angle-left pr-2"></i>Back</div> */}
                                                    <div className="mb-2 text-2xl font-bold text-zinc-700 dark:text-white sm:text-title-xl2 gradient-text">{ id ? 'Edit Ad': 'Create Ad' }</div>
                                                </div>
                                                <form onSubmit={submitForm}>
                                                    <div className="flex">
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="create date" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Create Date
                                                                        {/* <Calendar /> */}
                                                                        {/* <input 
                                                        className="text-zinc-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"/> */}
                                                                    </label>
                                                                </div>
                                                                <div className="date-picker-style mt-2">
                                                                    <DatePicker
                                                                        selected={createDate}
                                                                        onChange={changeCreateDate}
                                                                        minDate={new Date()}
                                                                        showIcon
                                                                        isClearable
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Title
                                                                    </label>
                                                                </div>
                                                                <input
                                                                    name="title"
                                                                    onChange={handTextDetails}
                                                                    defaultValue={textDetails.title || ''}
                                                                    className="pr-8 mt-2 text-zinc-700 placeholder-gray-600 px-4 py-2.5 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
    
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                    <div className="flex">
                                                        <div class="flex-grow pr-3">
                                                            <div className="pt-5">
                                                                <label for="title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                    Start Date
                                                                    {/* <input 
                                                        className="text-zinc-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"/> */}
                                                                </label>
                                                                <div className="date-picker-style mt-2">
                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        minDate={new Date()}
                                                                        maxDate={endDate}
                                                                        showIcon
                                                                        isClearable
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <label for="title" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                    End Date
                                                                    {/* <input 
                                                        className="text-zinc-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue"/> */}
                                                                </label>
                                                                <div className="date-picker-style mt-2">
                                                                    <DatePicker
                                                                        selected={endDate}
                                                                        onChange={(date) => setEndDate(date)}
                                                                        minDate={startDate}
                                                                        showIcon
                                                                        isClearable
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                    <div className="flex">
                                                        <div class="flex-grow pr-2">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="amount" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Total Amount
                                                                    </label>
                                                                </div>
    
                                                                <input name="amount" defaultValue={textDetails.amount} onChange={handTextDetails} className="pr-8 text-zinc-700 placeholder-gray-600 px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div className="pt-5">
                                                                <div>
                                                                    <label for="url" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                        Redirect URL
                                                                    </label>
                                                                </div>
                                                                <input name="url" defaultValue={textDetails.url || ''} onChange={handTextDetails} className="pr-8 text-zinc-700 placeholder-gray-600 px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-anchor-blue focus:bg-white focus:outline-none focus:shadow-outline focus:ring-1 ring-offset-current ring-offset-0 ring-anchor-blue" />
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                    <div className="pt-10 text-zinc-700 py-2.5 text-base ">
                                                        <div>
                                                            <label for="image" className="pt-5 text-zinc-700 py-2.5 text-base">
                                                                Upload Image
                                                            </label>
                                                        </div>
                                                        <input type="file" onChange={uploadImage} name="image" />
                                                        <img src={imageData.imagePreview} width={imageData.imagePreviewWidth} height={imageData.imagePrevieHeight} />
                                                    </div>
                                                    <div className="ckEditor text-zinc-700 text-base mt-2">
                                                        <div>
                                                            <label for="image" className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                                Deal Notes
                                                            </label>
                                                        </div>
                                                        <CustomCkEditor textData={textDetails.deal_notes} onChange={getTextEditorValue} />
                                                    </div>
    
                                                    <div className="pt-5 text-zinc-700 py-2.5 mt-2 text-base">
                                                        <div>
                                                            <input className="cursor-pointer" type="radio" name="status" defaultValue={1} onChange={handTextDetails} defaultChecked={textDetails.status ? "checked" : ""} /> Active
                                                        </div>
    
                                                        <div className="mt-2">
                                                            <input className="cursor-pointer" type="radio" name="status" defaultValue={0} onChange={handTextDetails} defaultChecked={!textDetails.status ? "checked" : ""}/> Inactive
                                                        </div>
                                                    </div>
    
                                                    <button type="submit" class="mt-5 px-9 cursor-pointer rounded-lg bg-blue-700 p-2 text-white gradient-bg">Submit</button>
                                                </form>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div> : ''
                }
            </React.Fragment>
        )
    }
}

export default AddAdvertisement